import React from "react";

import "./radio-checkbox.sass";

const RadioCheckbox = ({ label, name, value, checked, selected = null, onChange }) => {
  return (
    <span className="radio-checkbox">
      <input
        onChange={onChange}
        type="radio"
        name={name}
        value={value}
        className={"radio-checkbox_input"}
        id={`id${value}`}
        defaultChecked={checked}
        checked={selected === value}
      />
      <label htmlFor={`id${value}`} className={"radio-checkbox_label"}>
        {label}
      </label>
    </span>
  );
};

export default RadioCheckbox;
