import React, { memo, useMemo, useEffect, useState, useRef } from "react";
import IframeResizer from "iframe-resizer-react";
import { useUidmSSO } from "uidm-react-lib";

import Layout from "../../layout";
import Breadcrumbs from "../../layout/Breadcrumbs";

import { getUrls, CUSTOMERS_SEARCH_IFRAME_BASE_URL } from "../../const";
import api from "../../api";

import Describe from "./components/Describe";
import "./customers-search.sass";

const IFRAME_SRC = new URL("/customers-search-iframe", CUSTOMERS_SEARCH_IFRAME_BASE_URL);

const CustomersSearch = ({ menuConfig = {}, match: { params }, ...props }) => {
  const iframeRef = useRef(null);
  const [countryFullName, setCountryFullName] = useState("");
  const [iframeSrc, setIframeSrc] = useState();
  const { sso } = useUidmSSO();

  const formattedQuery = useMemo(() => {
    return unescape(params.q);
  }, [params.q]);

  useEffect(() => {
    IFRAME_SRC.searchParams.set("_cache", new Date().getTime());
    IFRAME_SRC.searchParams.set("country", params.id);
    IFRAME_SRC.searchParams.set("country_name", countryFullName);
    IFRAME_SRC.searchParams.set("commodity", params.code);
    IFRAME_SRC.searchParams.set("commodity_name", formattedQuery);
    setIframeSrc(IFRAME_SRC.toString());
  }, [params.id, params.code, formattedQuery, countryFullName]);

  useEffect(() => {
    if (!params.id || !params.code) return;
    api.getCountryShortInfo({ iso: params.id, tnved: params.code }).then(({ countryFullName }) => {
      if (countryFullName) setCountryFullName(countryFullName);
    });
  }, [sso.logged, params.id, params.code]);

  return (
    <Layout menuConfig={menuConfig} wrapperClassName="customers-search">
      <section className="section">
        <div className="container" style={{ display: "flow-root" }}>
          <Breadcrumbs
            items={[
              {
                href: getUrls("RESULT", {
                  q: params.q,
                  code: params.code,
                }),
                title: formattedQuery,
              },
              {
                href: getUrls("COUNTRY", params),
                title: countryFullName,
              },
              {
                href: null,
                title: "Потенциальные покупатели",
              },
            ]}
          />
          <Describe />
          {!sso.logged ? (
            <div className="download-btn" style={{ flexDirection: "column" }}>
              <p>Скачивание отчета доступно только авторизованным пользователям.</p>
              <span role="presentation" className="link" onClick={sso.login}>
                Войти
              </span>
            </div>
          ) : (
            <>
              <div className="row iframe-container">
                {!iframeSrc ? null : (
                  <IframeResizer
                    title="customers-search"
                    id="customers-search"
                    src={iframeSrc}
                    forwardRef={iframeRef}
                    heightCalculationMethod="taggedElement"
                    checkOrigin={process.env.NODE_ENV === "production"}
                    scrolling="no"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default memo(CustomersSearch);
