import React, { memo } from "react";
import Tooltip from "react-tooltip";
import { ReactComponent as InfoIcon } from "../../images/icons/info.svg";

import "./index.sass";

const ReactTooltip = ({ style, place = "right", borderColor = "none", text, children }) => (
  <div style={style}>
    <p className="tooltip__text" data-tip={text}>
      {children ? children : <InfoIcon />}
    </p>
    <Tooltip
      className="tooltip__tooltip"
      textColor="black!important"
      arrowColor="transparent"
      place={place}
      effect="solid"
      border={borderColor !== "none"}
      borderColor={borderColor}
      data-tip-disable="true"
    />
  </div>
);

export default memo(ReactTooltip);
