import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import "./index.sass";
import api from "../../api";

const FileLink = ({
  href,
  fileName,
  target = "_self",
  children,
  modifier = "normal",
  accepts = [],
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const cb = useCallback(
    (e) => {
      if (e.target.href) {
        return;
      }

      const headers = {};
      if (accepts.length) {
        headers["Accept"] = accepts.join(", ");
      }

      const { target: a } = e;
      e.preventDefault();
      setLoading(true);

      api
        .get({ baseUrl: "", url: href, options: { headers: headers, responseType: "blob" } })
        .then(
          (response) => {
            a.href = URL.createObjectURL(response.data);
            a.click();
          },
          (err) => {
            if (!err.response) {
              return history.push({ pathname: "/500" });
            }
            switch (err.response.status) {
              case 401:
                return history.push({ pathname: "/401" });
              case 404:
                return history.push({ pathname: "/404" });
              default:
                return history.push({ pathname: "/500" });
            }
          }
        )
        .finally(() => setLoading(false));
    },
    [href, accepts, history]
  );

  return (
    <span target={target} className={`link link--${modifier}`} download={fileName} onClick={cb}>
      {loading ? "Загрузка началась..." : children}
    </span>
  );
};

export default FileLink;
