import React, { memo } from "react";
import PropTypes from "prop-types";

const Close = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.210883 0.210883C0.492061 -0.0702944 0.947939 -0.0702944 1.22912 0.210883L6 4.98177L10.7709 0.210883C11.0521 -0.0702944 11.5079 -0.0702944 11.7891 0.210883C12.0703 0.492061 12.0703 0.947939 11.7891 1.22912L7.01823 6L11.7891 10.7709C12.0703 11.0521 12.0703 11.5079 11.7891 11.7891C11.5079 12.0703 11.0521 12.0703 10.7709 11.7891L6 7.01823L1.22912 11.7891C0.947939 12.0703 0.492061 12.0703 0.210883 11.7891C-0.0702944 11.5079 -0.0702944 11.0521 0.210883 10.7709L4.98177 6L0.210883 1.22912C-0.0702944 0.947939 -0.0702944 0.492061 0.210883 0.210883Z"
      fill={fill}
    />
  </svg>
);

Close.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

Close.defaultProps = {
  width: 12,
  height: 12,
  fill: "#fff",
};

export default memo(Close);
