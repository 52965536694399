/* eslint-disable jsx-a11y/click-events-have-key-events,
jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import { Badge } from "elk-uikit";
import autoCompleteStyles from "../../AutoComplete.module.scss";

import styles from "./Alternate.module.scss";

function DocumentsVariant({
  data: { groups = [], nomenclatures = [], goods = [] },
  onClickItem,
  // showAllHandler,
  clearInput,
  // toggleVisibility,
}) {
  const showAll = (event) => {
    // showAllHandler();
    clearInput();
    event.target.closest(`div.${autoCompleteStyles.wrapper}`).querySelector("input").blur();
    // toggleVisibility(false);
  };

  return (
    <div className={styles.listContainer}>
      {groups.length > 0 && (
        <>
          <div className={styles.title}>ОТРАСЛИ</div>
          <div className={styles.badgesWrapper}>
            {groups.map((group) => (
              <Badge key={group}>
                <span className={styles.badge}>{group}</span>
              </Badge>
            ))}
          </div>
        </>
      )}
      {nomenclatures.length > 0 && (
        <>
          <div className={styles.title}>ТН ВЭД</div>
          <ul className={styles.nomenclatureList}>
            {nomenclatures.map(({ name, code: { url, text } }) => (
              <li key={name} className={styles.listItem}>
                <button type="button">
                  <a href={url} rel="noopener noreferrer" className={styles.link}>
                    {text}
                  </a>
                  <div className={styles.listItemText}>
                    &nbsp; — &nbsp;
                    {name}
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
      {goods.length > 0 && (
        <>
          <div className={styles.title}>ТОВАРЫ</div>
          <ul>
            {goods.map((product) => (
              <li
                key={product.id}
                className={cn(styles.listItem, styles.productItem)}
                onClick={(event) => onClickItem(event, product)}
              >
                {product.name}
              </li>
            ))}
          </ul>
        </>
      )}
      <button className={styles.showAllButton} type="button" onClick={showAll}>
        Показать все
      </button>
    </div>
  );
}

DocumentsVariant.propTypes = {
  onClickItem: PropTypes.func.isRequired,
  // showAllHandler: PropTypes.func.isRequired,
  clearInput: PropTypes.func.isRequired,
  // toggleVisibility: PropTypes.func.isRequired,
  data: PropTypes.shape({
    groups: PropTypes.array,
    nomenclatures: PropTypes.array,
    goods: PropTypes.array,
  }),
};

DocumentsVariant.defaultProps = {
  data: [],
};

export default memo(DocumentsVariant);
