import React, { memo } from "react";

const Describe = () => (
  <div className="describe">
    <p>
      На данной странице вы можете скачать "Товарно-страновой отчет со списком потенциальных
      покупателей", а также ознакомиться с прилагаемым списком потенциальных покупателей в
      интерактивной таблице ниже.
    </p>
  </div>
);

export default memo(Describe);
