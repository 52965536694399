import React from "react";
import "./index.sass";

const BrowserLink = ({ href, target = "_self", children, modifier = "normal", ...other }) => (
  <a target={target} href={href} className={`link link--${modifier}`} {...other}>
    {children}
  </a>
);

export default BrowserLink;
