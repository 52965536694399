import React, { memo, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import cn from "classnames";
import { useUidmSSO } from "uidm-react-lib";

import api from "../../api";
import { ReactComponent as PDFIcon } from "../../images/icons/pdfFile.svg";
import { ReactComponent as DocumentIcon } from "../../images/icons/document2.svg";
import { getIconHandsByCountryCompanies } from "../../utils/index";

import { BrowserLink, FakeLink } from "../Link";

import "./index.sass";

const REPORT_NAME = "Товарно-страновой отчёт со списком потенциальных покупателей";

const Document = ({ doc: { id, title, description, tooltip, preview, file }, companies }) => {
  const { sso } = useUidmSSO();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const downloadCb = useCallback(
    (e) => {
      if (e.target.href) {
        return;
      }

      const { target: a } = e;
      e.preventDefault();
      setLoading(true);

      api
        .get({ baseUrl: "", url: file.url })
        .then(
          (response) => {
            a.href = response.data.url;
            a.click();
          },
          (err) => {
            if (!err.response) {
              return history.push({ pathname: "/500" });
            }
            switch (err.response.status) {
              case 401:
                return history.push({ pathname: "/401" });
              case 404:
                return history.push({ pathname: "/404" });
              default:
                return history.push({ pathname: "/500" });
            }
          }
        )
        .finally(() => setLoading(false));
    },
    [file.url, history]
  );

  const renderButtonsContainer = () => {
    if (sso.logged && preview && preview.url) {
      return (
        <div className="download__container__table">
          <div className="download__container__icons">
            {title === REPORT_NAME && getIconHandsByCountryCompanies(companies)}
            <DocumentIcon className="svg" />
          </div>
          <BrowserLink href={preview.url} modifier="small">
            Перейти к уточнению параметров и формированию отчета
          </BrowserLink>
        </div>
      );
    }
    return (
      <div className="download__container__button">
        {title === REPORT_NAME && getIconHandsByCountryCompanies(companies)}
        <PDFIcon className="svg" />
        {sso.logged ? (
          <BrowserLink target="_blank" modifier="small" onClick={downloadCb}>
            {loading ? "Ожидание ответа..." : "Скачать"}
          </BrowserLink>
        ) : (
          <div
            className={cn({
              "download--disabled": !sso.logged,
            })}
          >
            <FakeLink modifier="small">Скачать</FakeLink>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="document">
      <div className="document__block">
        <div className="document__title">
          {title}
          {tooltip ? (
            <span>
              <span
                data-tip
                data-for={`document-${id}__title_tooltips`}
                className="table__tooltip_question"
              />
              <ReactTooltip
                id={`document-${id}__title_tooltips`}
                type="light"
                className="table__tooltip_box_warning"
              >
                <p>{tooltip}</p>
              </ReactTooltip>
            </span>
          ) : null}
        </div>
        <div className="document__description">{description}</div>
      </div>
      <div className="document__info">
        <div className="download">
          {file ? (
            sso.logged && file.alert ? (
              <div className={cn({ download__container: true })}>
                <p className="download__message" style={{ textAlign: "center" }}>
                  {file.alert}
                </p>
              </div>
            ) : (
              <>
                <div className="download__container">{renderButtonsContainer()}</div>
                {false && file.size && (
                  <div className="download__filesize">{(file.size / 1000).toFixed(0)} Кбайт</div>
                )}
                {!sso.logged && (
                  <span className="download__message">
                    <p>Скачивание отчета доступно только авторизованным пользователям.</p>

                    <p role="presentation" className="download__login" onClick={sso.login}>
                      Войти
                    </p>
                  </span>
                )}
              </>
            )
          ) : (
            <p className="download__message" style={{ textAlign: "center" }}>
              Отчет временно недоступен
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Document);
