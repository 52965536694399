import React from "react";
import Layout from "../layout";
import { useHistory } from "react-router-dom";

const Error401 = ({ menuConfig }) => {
  const history = useHistory();
  return (
    <Layout title="401" menuConfig={menuConfig}>
      <section className="notfound__section">
        <div className="container" style={{ display: "flow-root" }}>
          <div className="row notfound__box">
            <div className="notfound__left">
              <div className="notfound__lg-title">401</div>
            </div>
            <div className="notfound__right">
              <h1 className="notfound__label">Ошибка 401</h1>
              <div className="notfound__descr">Пользователь не авторизован</div>
              <div className="notfound__small-text">
                Ваша сессия истекла, требуется повторная аутентификация
              </div>
              <button onClick={() => history.goBack()} className="btn btn-primary">
                Вернуться назад
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Error401;
