import React, { memo } from "react";
import { ReactComponent as InfoIcon2 } from "../../images/icons/info_2.svg";
import "./index.sass";
import ReactTooltip from "react-tooltip";

const NicheInformation = ({ data: { id, title, tooltip, options } }) => (
  <div className="niche-info-table__item">
    <div className="niche-info-table__sub-title">
      {title}
      {tooltip ? (
        <span className="niche-info-table__tooltips">
          <span
            data-tip
            data-iscapture="true"
            data-scroll-hide="false"
            data-for={`niche-info-table__tooltips-${id}`}
            data-offset="{'top': 5}"
          >
            <InfoIcon2 className={"niche-info-table__tooltips_icon"} />
            Новое
          </span>
          <ReactTooltip
            id={`niche-info-table__tooltips-${id}`}
            type="light"
            className="table__tooltip_box_warning"
          >
            {tooltip}
          </ReactTooltip>
        </span>
      ) : null}
    </div>
    {options.map(({ title }, oIndex) => (
      <div key={oIndex} className="niche-info-table__row">
        {title}
      </div>
    ))}
  </div>
);

const NichesInformation = ({ data }) => (
  <div className="niche-info-table__box">
    {data.map((item, index) => (
      <NicheInformation data={item} key={index} />
    ))}
  </div>
);

export default memo(NichesInformation);
