import { currencyFormat, percentFormat } from "../../utils";

const ratingSerializer = ({
  alpha2: iso,
  applied_tariff: avgAppliedTariff,
  barriers_number: barriersCount,
  comtrade_import2: importVolume,
  comtrade_import2_year: importVolumeYear,
  import_from_russia_part: importFromRussia,
  import_from_russia_part_year: importFromRussiaYear,
  name_ru: countryRu,
  niches_number: nichesCount,
  scoring_place: scoringPlace,
  tnved,
  companies,
}) => {
  return {
    avgAppliedTariff,
    avgAppliedTariffPercent: avgAppliedTariff
      ? percentFormat(avgAppliedTariff, { precision: 2, signed: false })
      : "\u2014",
    barriersCount,
    importFromRussia,
    importFromRussiaYear,
    importFromRussiaPercent: importFromRussia
      ? percentFormat(importFromRussia * 100, { precision: 2, signed: false })
      : "\u2014",
    countryRu,
    nichesCount,
    importVolume,
    importVolumeYear,
    importVolumeFormated: importVolume ? `$ ${currencyFormat(importVolume)}` : "\u2014",
    iso,
    scoringPlace,
    tnved,
    companies,
  };
};

export default ratingSerializer;
