import React from "react";
import Layout from "../layout";
import { useHistory } from "react-router-dom";

const Error500 = ({ menuConfig }) => {
  const history = useHistory();
  return (
    <Layout title="500" menuConfig={menuConfig}>
      <section className="notfound__section">
        <div className="container" style={{ display: "flow-root" }}>
          <div className="row notfound__box">
            <div className="notfound__left">
              <div className="notfound__lg-title">500</div>
            </div>

            <div className="notfound__right">
              <h1 className="notfound__label">Ошибка 500</h1>
              <div className="notfound__descr">Внутренняя ошибка сервера</div>
              <div className="notfound__small-text">К сожалению, что-то пошло не так</div>
              <button onClick={() => history.goBack()} className="btn btn-primary">
                Вернуться назад
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Error500;
