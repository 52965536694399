import React, { memo, useEffect, useState } from "react";

import { useUidmSSO } from "uidm-react-lib";
import { FileLink } from "../layout/Link";

const RatingDetails = ({
  match: {
    params: { tnved },
  },
}) => {
  const [region, setRegion] = useState(null);
  const [inn, setInn] = useState(null);
  const [fileName, setFileName] = useState("");
  const [href, setHref] = useState("");

  const { sso } = useUidmSSO();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    setRegion(params.get("region"));
    setInn(params.get("inn"));
  }, []);

  useEffect(() => {
    let fileName = `rating_details_${tnved}`;
    let href = `/api/${fileName}.xlsx`;
    if (region !== null && inn !== null) {
      fileName += `(region_${region}, inn_${inn})`;
      href += `?region=${encodeURIComponent(region)}&inn=${encodeURIComponent(inn)}`;
    } else if (region !== null) {
      fileName += `(region_${region})`;
      href += `?region=${encodeURIComponent(region)}`;
    } else if (inn !== null) {
      fileName += `(inn_${inn})`;
      href += `?inn=${encodeURIComponent(inn)}`;
    }
    fileName += ".xlsx";

    setFileName(fileName);
    setHref(href);
  }, [tnved, region, inn]);

  return sso ? (
    <div
      className="container"
      style={{ display: "flow-root", height: "100vh", placeItems: "center" }}
    >
      <div className="row" style={{ height: "100%", placeContent: "center" }}>
        {sso.logged ? (
          <FileLink
            href={href}
            fileName={fileName}
            target="_blank"
            accepts={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
          >
            Скачать
          </FileLink>
        ) : (
          <>
            <p>Скачивание отчета доступно только авторизованным пользователям.</p>
            <p role="presentation" className="download__login" onClick={sso.login}>
              Войти
            </p>
          </>
        )}
      </div>
    </div>
  ) : null;
};

export default memo(RatingDetails);
