import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { Header } from "swrec-menu";
import SwitchHint from "./SwitchHint";
import connect from "storeon/react/connect";
import { useUidmSSO } from "uidm-react-lib";

import { useDocumentTitle } from "../utils";

import TermsModal from "./TermsModal";
import "./styles.sass";

const ROUTES_WITHOUT_HINTS = ["/500", "/result/404"];

const containerBreakpoints = {
  0: {
    maxWidth: "328px",
    width: "100%",
    margin: "0 auto",
    padding: "0 8px",
  },
  575: {
    maxWidth: "328px",
    width: "100%",
    margin: "0 auto",
    padding: "0 8px",
  },
  820: {
    maxWidth: "800px",
    width: "100%",
    margin: "0 auto",
    padding: "0 8px",
  },
  1360: {
    maxWidth: "1296px",
    width: "100%",
    margin: "0 auto",
    padding: "0 8px",
  },
  1521: {
    maxWidth: "1440px",
    width: "100%",
    margin: "0 auto",
    padding: "0 8px",
  },
};

const Layout = ({
  children,
  menuConfig: { uidm, menu },
  title = "Навигатор экспортёра",
  wrapperClassName = "",
  user,
}) => {
  const [termsModalIsOpen, setTermsModalIsOpen] = useState(false);
  const { menuApiUrl: portalUrl } = menu;
  const {
    location: { pathname },
  } = useHistory();

  useDocumentTitle(title);

  const [sso] = useUidmSSO();
  const logoutHandler = () => {
    sso.logout();
    localStorage.removeItem("storedFormData");
  };

  return (
    <div>
      <Header
        isWantUseAnalytics={false}
        notificationComponent={() => {
          // временно скрываем отображение колокольчика
          return null;
        }}
        withSSO
        showLogin
        showSubMenu
        sso={{
          userData: {
            displayName: user?.displayName || "",
            organizations: [
              {
                displayName: user.displayNameCurrentOrgId || "",
              },
            ],
          },
          login: sso.login,
          logout: logoutHandler,
          logged: sso.logged,
          getCurrentTokenInfo: async () => ({ mdm_id: user?.mdm_id || 0 }),
        }}
        {...menu}
        initConfig={uidm}
        containerBreakpoints={containerBreakpoints}
      />
      <div className={cn("mainWrapper", wrapperClassName)}>
        {children}
        <div className="terms-footer">
          * Используя данный Сервис платформы "Мой экспорт" Вы соглашаетесь с{" "}
          <a href="#" onClick={() => setTermsModalIsOpen(true)}>
            условиями использования
          </a>{" "}
          данного сервиса
          <TermsModal
            portalUrl={portalUrl}
            isOpen={termsModalIsOpen}
            closeModal={() => setTermsModalIsOpen(false)}
          />
        </div>
      </div>
      {/*
      <Footer />
      */}
      {!ROUTES_WITHOUT_HINTS.includes(pathname) && <SwitchHint />}
    </div>
  );
};

export default connect("user", Layout);
