import React, { memo } from "react";
import PropTypes from "prop-types";

const ArrowBig = ({ fill, styleArrow }) => (
  <svg
    width="129"
    height="105"
    style={styleArrow}
    viewBox="0 0 129 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M126.652 0.7662C126.523 0.522107 126.22 0.428906 125.976 0.55803L121.999 2.66223C121.755 2.79135 121.661 3.0939 121.79 3.338C121.92 3.58209 122.222 3.67529 122.466 3.54617L126.002 1.67577L127.872 5.21153C128.002 5.45562 128.304 5.54883 128.548 5.4197C128.792 5.29058 128.885 4.98803 128.756 4.74393L126.652 0.7662ZM1.03282 104.63C39.0153 102.131 103.515 80.2084 126.226 2.64816L125.266 2.36715C102.715 79.3835 38.6992 101.15 0.967176 103.632L1.03282 104.63ZM126.226 2.64816C126.311 2.35588 126.396 2.0628 126.481 1.76894L125.519 1.49292C125.436 1.78512 125.351 2.07653 125.266 2.36715L126.226 2.64816ZM125.403 5.31829L126.224 2.65485L125.268 2.36046L124.447 5.0239L125.403 5.31829ZM126.224 2.65485L126.688 1.1472L125.732 0.852801L125.268 2.36046L126.224 2.65485Z"
      fill={fill}
    />
  </svg>
);

ArrowBig.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  styleArrow: PropTypes.shape({}),
};

ArrowBig.defaultProps = {
  fill: "#6177FF",
  styleArrow: {},
};

export default memo(ArrowBig);
