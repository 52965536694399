import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { UidmProvider } from "uidm-react-lib";
import "./index.sass";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import StoreContext from "storeon/react/context";
import { store } from "./store";

import { sleep } from "./utils";

(async () => {
  while (window.CONFIG === undefined) {
    await sleep(0);
  }
  const config = window.CONFIG;

  ReactDOM.render(
    <UidmProvider initConfig={config.uidm}>
      <StoreContext.Provider value={store}>
        <App config={config} />
      </StoreContext.Provider>
    </UidmProvider>,
    document.getElementById("root")
  );
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
})();
