import React, { memo } from "react";
import "./trade-product.sass";

const TradeProduct = ({ data }) => {
  if (!data) return null;
  const { import_info, export_info } = data;
  return (
    <div className="trade-info-table__box">
      <div className="trade-info-table__two-column">
        <div className="trade-info-table__two-column-left">
          <div className="trade-info-table__row">
            <div className="trade-info-table__head">Импорт</div>
          </div>
          <div className="trade-info-table__row">
            <div className="trade-info-table__sec-label">Обьем</div>
            <div className="trade-info-table__prim-col">
              <span>$ </span>
              {import_info.value}
              <span className="trade-info-table__trd-col">
                {import_info.period ? (
                  <span className="trade-info-table__tags">{import_info.period}</span>
                ) : null}
              </span>
            </div>
          </div>
          <div className="trade-info-table__row">
            <div className="trade-info-table__sec-label">Динамика</div>
            <div className="trade-info-table__prim-col">
              <span>$ </span>
              {import_info.forecast.value}
              <span className="prim-col__accent">
                &nbsp;({import_info.forecast.percent.replace(".", ",")})
              </span>
              <div className="trade-info-table__trd-col">
                {import_info.forecast.period ? (
                  <div className="trade-info-table__tags">{import_info.forecast.period}</div>
                ) : null}
              </div>
            </div>
          </div>
          {import_info.russia !== null && (
            <div className="trade-info-table__row">
              <div className="trade-info-table__sec-label">Доля России</div>
              <div className="trade-info-table__prim-col">
                {import_info.russia.percent}
                <span className="trade-info-table__trd-col">
                  {import_info.russia.period ? (
                    <span className="trade-info-table__tags">{import_info.russia.period}</span>
                  ) : null}
                </span>
              </div>
            </div>
          )}
        </div>
        {/* left col*/}
        <div className="trade-info-table__two-column-right">
          <div className="trade-info-table__row">
            <div className="trade-info-table__head">Экспорт</div>
          </div>
          <div className="trade-info-table__row">
            <div className="trade-info-table__sec-label">Обьем</div>
            <div className="trade-info-table__prim-col">
              <span>$ </span>
              {export_info.value}
              <div className="trade-info-table__trd-col">
                {export_info.period ? (
                  <div className="trade-info-table__tags">{export_info.period}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="trade-info-table__row">
            <div className="trade-info-table__sec-label">Динамика</div>
            <div className="trade-info-table__prim-col">
              <span>$ </span>
              {export_info.forecast.value}
              <span className="prim-col__accent">
                &nbsp;({export_info.forecast.percent.replace(".", ",")})
              </span>
              <div className="trade-info-table__trd-col">
                {export_info.forecast.period ? (
                  <div className="trade-info-table__tags">{export_info.forecast.period}</div>
                ) : null}
              </div>
            </div>
          </div>
          {import_info.russia !== null && <div className="trade-info-table__row">&nbsp;</div>}
        </div>
        {/* right col*/}
      </div>
    </div>
  );
};

export default memo(TradeProduct);
