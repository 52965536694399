import { currencyFormat, numberFormat, percentFormat, isEmpty } from "../../utils";

const countrySerializer = ({
  featured: { documents, services } = { documents: [], services: [] },
  about,
  product: { info: infoProduct },
  import_from_russia: importFromRussia,
  barriers,
  niches,
  companies,
}) => {
  const { name_ru: countryFullName } = about;
  return {
    countryFullName,
    featured: {
      title: "Рекомендованные продукты",
      documents,
      services,
      companies,
    },
    infoProduct: {
      title: "Информация о торговле товаром",
      data: infoProduct
        ? {
            import_info: infoProduct
              ? {
                  value: currencyFormat(infoProduct.import),
                  period: infoProduct.import_year,
                  russia: !isEmpty(infoProduct.import_from_russia_part)
                    ? {
                        percent: percentFormat(infoProduct.import_from_russia_part * 100, {
                          signed: false,
                        }),
                        period: infoProduct.import_from_russia_part_year,
                      }
                    : null,
                  forecast: {
                    value: currencyFormat(infoProduct.import_dynamics_abs),
                    percent: percentFormat(infoProduct.import_dynamics_rel),
                    period:
                      infoProduct.import_year && infoProduct.import_dynamics_year
                        ? `${infoProduct.import_year}/${infoProduct.import_dynamics_year}`
                        : infoProduct.import_dynamics_year,
                  },
                }
              : { forecast: {} },
            export_info: infoProduct
              ? {
                  value: currencyFormat(infoProduct.export),
                  period: infoProduct.export_year,
                  forecast: {
                    value: currencyFormat(infoProduct.export_dynamics_abs),
                    percent: percentFormat(infoProduct.export_dynamics_rel),
                    period:
                      infoProduct.export_year && infoProduct.export_dynamics_year
                        ? `${infoProduct.export_year}/${infoProduct.export_dynamics_year}`
                        : infoProduct.export_dynamics_year,
                  },
                }
              : { forecast: {} },
          }
        : null,
    },
    importFromRussia: {
      title: "Самые экспортируемые товары страной",
      data: importFromRussia
        ? importFromRussia.map((item) => ({
            ...item,
            amount: currencyFormat(item.amount),
          }))
        : [],
    },
    about: {
      title: "Общие сведения о стране",
      data: [
        {
          label: "Столица",
          value: about.capital_ru,
          unit: null,
          year: null,
          forecast: null,
        },
        {
          label: "Площадь",
          value: numberFormat(about.square),
          unit: "км²",
          year: null,
        },
        {
          label: "Население",
          value: numberFormat(about.population, 1),
          unit: "млн чел.",
          year: null,
          forecast: null,
        },
        {
          label: "ВВП",
          value: `${currencyFormat(about.gdp2, 1)} млрд`,
          unit: "$",
          year: about.gdp2_year,
          forecast: {
            percent: about.gdp1
              ? percentFormat(((about.gdp2 - about.gdp1) * 100) / about.gdp1)
              : "\u2014",
            period:
              about.gdp1_year && about.gdp2_year
                ? `${about.gdp2_year}/${about.gdp1_year}`
                : about.gdp1_year,
          },
        },
        {
          label: "ВВП ППС на душу населения",
          value: currencyFormat(about.gdp_per_capita2, 1),
          unit: "$",
          year: about.gdp_per_capita2_year,
          forecast: {
            percent: about.gdp_per_capita1
              ? percentFormat(
                  ((about.gdp_per_capita2 - about.gdp_per_capita1) * 100) / about.gdp_per_capita1
                )
              : "\u2014",
            period:
              about.gdp_per_capita1_year && about.gdp_per_capita2_year
                ? `${about.gdp_per_capita2_year}/${about.gdp_per_capita1_year}`
                : about.gdp_per_capita1_year,
          },
        },
        {
          label: "Индекс Doing Business",
          value: about.db_index2,
          unit: "место",
          year: about.db_index2_year,
          forecast: {
            percent: `${about.db_index1} место`,
            period: about.db_index1_year,
          },
        },
      ],
    },
    barriers: barriers
      ? {
          ...barriers,
          info: {
            avgAppliedTariff: barriers?.info?.applied_tariff
              ? percentFormat(barriers.info.applied_tariff, { signed: false })
              : null,
          },
          title: "Информация о барьерах",
          summary: [
            {
              label: "Действующие",
              value: barriers.counts.active,
            },
            {
              label: "В статусе расследования",
              value: barriers.counts.investigation,
            },
            {
              label: "Принятые, но не вступившие в силу",
              value: barriers.counts.adopted_not_in_force,
            },
            {
              label: "Всего",
              value: barriers.counts.total,
            },
          ],
        }
      : {},
    niches: niches
      ? {
          title: "Информация о нишах",
          data:
            niches.map(({ name, amount, quantity, unit, positions, ...niche }) => {
              let title = name;
              if (amount) {
                title += ` на сумму ${currencyFormat(amount)}$`;
              }
              if (quantity) {
                title += `, в объеме ${numberFormat(quantity)}${unit || ""}`;
              }
              return {
                ...niche,
                title: title,
                options: positions.map(({ tnved, name, percentage }) => {
                  return {
                    title: `${tnved} ${name} ${percentFormat(percentage, { signed: false })}`,
                  };
                }),
              };
            }) || [],
        }
      : {},
  };
};

export default countrySerializer;
