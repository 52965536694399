const HINTS = {
  searchProcess: {
    text: "Введите код ТН ВЭД или название товара, который вы хотите экспортировать",
  },
  tableSelectCountry: {
    text:
      "Нажмите для просмотра макроэкономических показателей и статистики торговли выбранным " +
      "товаром и для скачивания товарно-странового отчета",
  },
  mapCountry: {
    text:
      "Воспользуйтесь картографической схемой, на которой отражены показатели торговли " +
      "выбранным товаром",
  },
  diagram: {
    text:
      "Данный инфоблок позволяет понять, какую долю в экспорте России выбранного вами товара " +
      "занимает та или иная страна",
  },
  tableSelectCountryBtn: {
    text:
      "Перейдите для просмотра отчета по товару со списком потенциальных покупателей, " +
      " отчета о торговле России со страной   информации о нетарифных барьерах и экспертно " +
      "выявленных нишах и др.",
  },
  niches: {
    text: "Экспертно выявленная товарная ниша",
  },
};

export default HINTS;
