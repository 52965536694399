import React, { memo } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./Default.module.scss";
import { Typography } from "elk-uikit";

function DefaultLayout({
  dataSource,
  onClickItem,
  textToHighLight,
  index,
  arrayOfProps: [titleProp, subTitleProp, tagProp],
  isMoreButton,
  isShowMore,
  // showAllHandler,
  showMore,
  fullData,
  elementInItem,
  onMouseLeave,
  onEnterBlur,
  rightElementInItem,
}) {
  const highlightText = (originString = "", partToHighlight) => {
    if (Number.isNaN(Number(originString.substr(0, 6)))) {
      return (
        <>
          <span>{originString}</span>
        </>
      );
    }
    return (
      <>
        <span className={styles.bold}>{originString.substr(0, 6)}</span>
        <span>{originString.substr(6, originString.length)}</span>
      </>
    );
  };

  const onShowAll = () => showMore(true);

  return (
    <ul className={styles.listContainer}>
      {dataSource.map((item, itemIndex) => (
        <li
          role="presentation"
          tabIndex={itemIndex}
          className={`${styles.listItem} ${index === itemIndex ? styles.selected : ""}`}
          key={item.uuid || itemIndex}
          onClick={(e) => onClickItem(e, item)}
        >
          <div
            className={cn(styles.titleWrapper, {
              [styles.inRow]: elementInItem,
            })}
          >
            {titleProp && (
              <Typography variant="body" size="medium" className={styles.title}>
                {highlightText(item[titleProp], textToHighLight)}
              </Typography>
            )}
            {subTitleProp && (
              <Typography variant="body" size="medium" className={styles.subTitle}>
                {item[subTitleProp]}
              </Typography>
            )}
            {elementInItem ? elementInItem(item) : null}
          </div>
          {rightElementInItem ? rightElementInItem(item) : null}
          {tagProp && (
            <Typography variant="body" size="medium" className={styles.tag}>
              {item[tagProp]}
            </Typography>
          )}
        </li>
      ))}
      {!isShowMore && isMoreButton && fullData.length > 4 && (
        <li className={styles.showAllButton}>
          <div
            role="button"
            tabIndex="0"
            onKeyPress={(event) => {
              if (event.key === "Enter") onEnterBlur(event);
            }}
            onClick={onShowAll}
          >
            {`Показать все (${fullData.length})`}
          </div>
        </li>
      )}
    </ul>
  );
}

DefaultLayout.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object),
  arrayOfProps: PropTypes.arrayOf(PropTypes.string),
  onClickItem: PropTypes.func,
  showMore: PropTypes.func,
  textToHighLight: PropTypes.string,
  index: PropTypes.number,
  isMoreButton: PropTypes.bool,
  isShowMore: PropTypes.bool,
  // showAllHandler: PropTypes.func,
  onMouseLeave: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onEnterBlur: PropTypes.func,
  fullData: PropTypes.arrayOf(PropTypes.object),
  elementInItem: PropTypes.func,
  rightElementInItem: PropTypes.func,
};

DefaultLayout.defaultProps = {
  dataSource: [],
  arrayOfProps: [],
  onClickItem: () => false,
  textToHighLight: "",
  index: -1,
  isMoreButton: false,
  isShowMore: false,
  showMore: () => {},
  // showAllHandler: () => false,
  onMouseLeave: false,
  onEnterBlur: () => false,
  fullData: [],
  elementInItem: null,
  rightElementInItem: null,
};

export default memo(DefaultLayout);
