import React, { memo } from "react";
import connect from "storeon/react/connect";

import SearchNavigator from "../../components/SearchNavigator";
import Layout from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs/breadcrumbs";

import DescribeMainPage from "./components/DescribeMainPage";

const Home = ({ menuConfig, hint: { isOn } }) => {
  return (
    <Layout menuConfig={menuConfig}>
      <section className="search-section">
        <div className="container" style={{ display: "flow-root" }}>
          <Breadcrumbs />
          <DescribeMainPage />
          <div className="row">
            <div className="main-heading">
              <h1 className="main-heading-label">Поиск рынков сбыта</h1>
            </div>
            <SearchNavigator isShowTop isShadow />
          </div>
          {/* end row */}
        </div>
      </section>
    </Layout>
  );
};

export default connect("hint", memo(Home));
