import Close from "./Close";
import Add from "./Add";
import ArrowBig from "./ArrowBig";
import ArrowMiddle from "./ArrowMiddle";
import ArrowSmall from "./ArrowSmall";
import Info from "./Info";
import Hands from "./Hands";

export default {
  Close,
  Add,
  ArrowBig,
  ArrowMiddle,
  ArrowSmall,
  Info,
  Hands,
};
