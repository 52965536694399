import BaseApi, { getLimitOffset } from "@garpix/base-api";
import axios from "axios";
import ratingSerializer from "./serializers/rating";
import countrySerializer from "./serializers/country";
import searchSerializer from "./serializers/search";
import barrierSerializer from "./serializers/barrier";
import { getAccessToken } from "../utils";

class Api extends BaseApi {
  getAuthToken = async () => {
    if (this.sso && this.sso.logged) {
      return getAccessToken(this.sso.params);
    }
    return null;
  };

  get = async ({ baseUrl = this.url, url, params, options = {} }) => {
    const headers = options.headers || {};

    const token = await this.getAuthToken();
    if (token) {
      headers["Authorization"] = `Bearer sso_1.0_${token}`;
    }

    return axios({
      ...options,
      method: "get",
      params: params,
      url: `${baseUrl}${url}`,
      headers: headers,
    });
  };

  post = async ({ baseUrl = this.url, url, params, data, options = {} }) => {
    const headers = options.headers || {};

    const token = await this.getAuthToken();
    if (token) {
      headers["Authorization"] = `Bearer sso_1.0_${token}`;
    }

    return axios({
      ...options,
      method: "post",
      params: params,
      data: data,
      url: `${baseUrl}${url}`,
      headers: headers,
    });
  };

  search = async ({ q = "" }) => {
    const res = await this.get({
      url: "/search",
      params: { q },
    });
    return res.data.map(searchSerializer);
  };

  getExtra = async ({ q = "", code = "" }) => {
    const result = [];
    await Promise.all(
      code.map(async (item) => {
        const { data } = await this.get({ url: "/search/extra", params: { q: "", code: item } });
        result.push(data);
      })
    );

    return result.flat();
  };

  getListCountries = async () => {
    const res = await this.get({
      url: "/countries/about",
      params: { sort: "name_ru" },
    });
    return res.data;
  };

  getRating = async ({ sort, tnved, page = 1, rowsPerPage, country_ru }) => {
    const { limit, offset } = getLimitOffset(page, rowsPerPage);
    const res = await this.get({
      url: "/rating",
      params: {
        tnved,
        sort,
        offset,
        limit,
        country_ru,
      },
    });
    return {
      ...res.data,
      result: res.data.result.map(ratingSerializer),
    };
  };

  getMap = async ({ tnved, type }) => {
    const res = await this.get({
      url: `/charts/map/${type}/${tnved}`,
    });
    return res.data;
  };

  getProductExport = async ({ tnved, iso }) => {
    const res = await this.get({
      url: `/charts/bar/russian_export/${tnved}`,
      params: {
        iso: iso || null,
      },
    });
    return res.data;
  };

  getCountryShortInfo = async ({ iso, tnved }) => {
    const res = await this.get({
      url: `/countries/${iso}/short`,
      params: {
        tnved: tnved || null,
      },
    });
    return countrySerializer(res.data);
  };

  getCountry = async ({ iso, tnved, tnvedName }) => {
    const res = await this.get({
      url: `/countries/${iso}`,
      params: {
        tnved,
        tnved_name: tnvedName,
      },
    });
    return countrySerializer(res.data);
  };

  getBarrier = async ({ iso, tnved }) => {
    const res = await this.get({
      url: `/countries/${iso}/barriers/${tnved}/counts`,
    });
    return barrierSerializer(res.data);
  };

  getPopularQueries = async () => {
    const res = await this.get({ url: "/search/popular" });
    return res.data;
  };

  sendFeedback = async ({ id, search_method, value }) => {
    await this.post({
      url: "/search/feedback",
      data: {
        id: id,
        search_method,
        search_string: value,
      },
    });
  };

  getCountryProductReport = async ({ iso, tnved, ...params }) => {
    const res = await this.get({ url: `/reports/country_product/${iso}/${tnved}`, params });
    return res.data;
  };
}

const api = new Api("/api");

export default api;
