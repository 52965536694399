import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Button, Icons } from "elk-uikit";

import "./modal.css";

class ModalBody extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.el.style.display = "contents";
  }
  componentDidMount() {
    document.body.style.overflow = "hidden";
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.style.overflow = "";
    document.body.removeChild(this.el);
  }

  onRequestClose = (e) => {
    const target = e.target;
    if (target.className === "modal-overlay") {
      this.props.closeModal();
    }
  };

  render() {
    const { children, isOpen, closeModal, className } = this.props;

    return ReactDOM.createPortal(
      <div
        className={`modal-overlay`}
        onClick={(e) => this.onRequestClose(e)}
        style={{ display: `${!isOpen ? "none" : "block"}` }}
      >
        <div className={`modal-dialog modal-dialog-centered ${className}`}>
          <div className="modal-content">
            <Button
              variant="icon"
              onClick={() => closeModal()}
              className="btn-close"
              width="40px"
              icon={<Icons.Close width={24} height={24} />}
            />
            {children}
          </div>
        </div>
      </div>,
      this.el
    );
  }
}

export default ModalBody;
