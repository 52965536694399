import React, { memo, useEffect, useMemo, useState } from "react";
import { useUidmSSO } from "uidm-react-lib";
import connect from "storeon/react/connect";

import { Breadcrumbs } from "../../layout/Breadcrumbs/breadcrumbs";
import Layout from "../../layout";
import Sidebar from "../../layout/Sidebar";
import BackBtn from "../../layout/BackBtn";
import Loader from "../../layout/Loader";
import { BrowserLink } from "../../layout/Link";
import CountryFlag from "../../layout/CountryFlag";
import api from "../../api";
import { getUrls } from "../../const";
import InfoBlock from "../../components/InfoBlock";

import { componentsByType } from "./helpers";

import "./country.sass";

const Country = ({ match: { params }, history, hint, menuConfig }) => {
  const [data, setData] = useState(null);
  const [dataEntries, setEntries] = useState(null);
  const [sidebarList, setSidebarList] = useState([]);
  const [iso, setIso] = useState(null);
  const [countryFullName, setCountryFullName] = useState("");
  const [res, setRes] = useState(null);
  const { sso } = useUidmSSO();

  const formattedQuery = useMemo(() => {
    return unescape(params.q);
  }, [params.q]);

  useEffect(() => {
    async function fetchData() {
      return await api.getCountry({
        iso: params.id,
        tnved: params.code,
        tnvedName: formattedQuery,
      });
    }

    if (params.id && params.code && formattedQuery) {
      fetchData()
        .then((res) => {
          if (res.featured && res.featured.documents) {
            res.featured.documents = res.featured.documents.reverse();
          }
          setRes({ iso: params.id, ...res });
        })
        .catch(() => {
          history.push({ pathname: "/500" });
        });
    }
  }, [history, sso.logged, formattedQuery, params.id, params.code]);

  useEffect(() => {
    if (res) {
      const { countryFullName: name, iso, ...data } = res;
      setCountryFullName(name);
      setIso(iso);
      setData(data);

      const ent = Object.entries(data);
      [ent[1], ent[2]] = [ent[2], ent[1]];
      [ent[2], ent[3]] = [ent[3], ent[2]];

      setEntries(ent);
    }
  }, [res]);

  useEffect(() => {
    if (data) {
      setSidebarList(
        Object.keys(data).map((key) => ({
          title: data[key].title,
          href: `#section-${key}`,
          Link: BrowserLink,
        }))
      );
    }
  }, [data]);

  return (
    <Layout
      title={`${countryFullName} ${params.code} - ${formattedQuery}`}
      wrapperClassName="country-wrapper"
      menuConfig={menuConfig}
    >
      <Breadcrumbs
        items={[
          {
            href: getUrls("RESULT", {
              q: params.q,
              code: params.code,
            }),
            title: formattedQuery,
          },
          {
            href: null,
            title: countryFullName,
          },
        ]}
      />
      <div className="country-container">
        <div className="row">
          <div className="country">
            <BackBtn
              path={getUrls("RESULT", {
                q: params.q,
                code: params.code,
              })}
            />
            <div className="country__content">
              {iso === null ? (
                <Loader />
              ) : (
                <>
                  <div className="country__header">
                    <h1 className="country__title">{countryFullName}</h1>
                    {/*<img style={{width: '72px'}} src={countriesIcon[iso]} alt=""/>*/}
                    <CountryFlag iso={iso} width={72} />
                  </div>
                  {dataEntries.length !== 0 &&
                    dataEntries.map(([key, value]) => {
                      const Component = componentsByType[key];
                      return Component ? (
                        <div className="country__section" id={`section-${key}`} key={key}>
                          <Component
                            data={value}
                            isShowHint={hint.isOn}
                            product={params}
                            menuConfig={menuConfig}
                          />
                        </div>
                      ) : null;
                    })}
                  <InfoBlock />
                </>
              )}
            </div>
            <Sidebar title="Содержимое страницы" items={sidebarList} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default connect("hint", memo(Country));
