import React, { memo } from "react";
import "./about-table.sass";

const AboutTable = ({ data = [] }) => {
  const withForecast = [];
  const withoutForecast = [];

  data &&
    data.forEach((item) => {
      item.forecast ? withForecast.push(item) : withoutForecast.push(item);
    });

  return (
    <div className="more-info-table__box">
      <div className="more-info-table__row">
        {withoutForecast.map(({ label, unit, value, year }, index) => {
          return (
            <div key={index} className="more-info-table__item more-info-table__item--min">
              <div className="more-info-table__label">{label}</div>
              <div className="more-info-table__value">
                <div
                  className={`more-info-table__prim-col ${index === 0 ? "prim-col__accent" : ""}`}
                >
                  {unit === "$" ? `${unit || ""} ${value}` : `${value} ${unit || ""}`}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="more-info-table__row">
        {withForecast.map(({ label, unit, value, forecast, year }, index) => {
          return (
            <div key={index} className="more-info-table__item more-info-table__item--max">
              <div className="more-info-table__label">{label}</div>
              <div className="more-info-table__value">
                <div
                  className={`more-info-table__prim-col ${index === 0 ? "prim-col__accent" : ""}`}
                >
                  {unit === "$" ? `${unit || ""} ${value}` : `${value} ${unit || ""}`}
                </div>
                <div className="more-info-table__tags-wrapper">
                  <div className="more-info-table__tags">{year}</div>
                  <div className="more-info-table__forecast-block">
                    <div className="prim-col__accent">{forecast.percent.replace(".", ",")}</div>
                    <div className="more-info-table__tags">{forecast.period}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(AboutTable);
