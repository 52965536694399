import React from "react";

import MainTitle from "../../../layout/MainTitle";
import Document from "../../../layout/Document";
import Service from "../../../layout/Service";
import AboutTable from "../../../layout/AboutTable";
import TradeProduct from "../../../layout/TradeProduct";
import ImportFromRussia from "../../../layout/ImportFromRussia";
import BarriersInformation from "../../../layout/BarrierInformation";
import NichesInformation from "../../../layout/NicheInformation";
import Hint from "../../../components/Tooltip";
import HINTS from "../../../utils/hints";

import "../country.sass";

const componentsByType = {
  featured: ({
    data: { title, documents = [], services = [], companies = "none" },
    menuConfig: {
      menu: { menuApiUrl },
    },
  }) => {
    const SELECTION_OF_MARKETPLACES =
      window?._env?.REACT_APP_SELECTION_OF_MARKETPLACES ||
      window?._ENV?.REACT_APP_SELECTION_OF_MARKETPLACES ||
      process?.env?.REACT_APP_SELECTION_OF_MARKETPLACES ||
      `${menuApiUrl.trim(
        "/"
      )}/services/business/Prodvizhenie_na_vneshnie_rynki/Elektronnye_kanaly_torgovli/Podbor_i_razmeshenie?_ga=2.201159342.1252273999.1645683876-1703683486.1645533221`;

    const ANALYTIC_PRODUCTS_LINK =
      window?._env?.REACT_APP_ANALYTIC_PRODUCTS_LINK ||
      window?._ENV?.REACT_APP_ANALYTIC_PRODUCTS_LINK ||
      process?.env?.REACT_APP_ANALYTIC_PRODUCTS_LINK ||
      `${menuApiUrl.trim(
        "/"
      )}/services/business/Analiticheskie_otchety_i_issledovaniya/Analiticheskie_uslugi`;

    const customServices = [];
    customServices.push({
      name: "Другие аналитические продукты",
      description:
        "В каталоге сервисов доступны другие аналитические продукты, позволяющие провести более полный и детальный анализ по перспективам экспорта",
      url: ANALYTIC_PRODUCTS_LINK,
    });
    services.push({
      name: "Подбор маркетплейсов",
      description:
        "Определите подходящую международную или зарубежную торговую площадку для онлайн-экспорта",
      tooltip: "Переход к подбору маркетплейсов на портале РЭЦ",
      url: SELECTION_OF_MARKETPLACES,
    });
    return (
      <>
        <MainTitle>{title}</MainTitle>
        <div className="country__documents">
          {documents.map((item, index) => {
            return <Document companies={companies} key={index} doc={{ id: index, ...item }} />;
          })}
          {customServices.map((item, index) => {
            return <Service key={index} service={{ id: index, ...item }} />;
          })}
        </div>
        {services.map((item, index) => {
          return <Service key={index} service={{ id: index, ...item }} />;
        })}
      </>
    );
  },
  about: ({ data: { title, data } }) => (
    <>
      <MainTitle>{title}</MainTitle>
      <AboutTable data={data} />
    </>
  ),
  infoProduct: ({ data: { title, data }, product }) => (
    <>
      <MainTitle>
        {title}
        <span className="main-title__small">
          <span className="main-title__small_article">{product.code}</span>
          <span className="main-title__small_label">{unescape(product.q)}</span>
        </span>
      </MainTitle>
      {data ? <TradeProduct data={data} /> : <p>Нет информации</p>}
    </>
  ),
  importFromRussia: ({ data: { title, data } }) => (
    <>
      <MainTitle>{title}</MainTitle>
      <ImportFromRussia data={data} />
    </>
  ),
  barriers: ({
    data: { title, info, counts, summary, data },
    isShowHint,
    hint = HINTS.barriers,
  }) => (
    <>
      <MainTitle>
        {title}
        <span className="main-title__small main-title__barriers">
          <span className="main-title__small_label">Количество нетарифных барьеров:</span>
          <span className="main-title__small_article">&nbsp;{counts.total}</span>
        </span>
        {info.avgAppliedTariff && (
          <span className="main-title__small main-title__barriers">
            <span className="main-title__small_label">Средний применяемый тариф:</span>
            <span className="main-title__small_article">&nbsp;{info.avgAppliedTariff}</span>
          </span>
        )}
      </MainTitle>
      <BarriersInformation summary={summary} data={data} />
    </>
  ),
  niches: ({ data: { title, data }, isShowHint, hint = HINTS.niches }) => (
    <>
      <Hint
        isShow={isShowHint}
        message={hint.text}
        maxWidth="200"
        arrowSize="small"
        styleTooltip={{
          top: "40px",
          left: "-215px",
        }}
        styleArrow={{
          position: "absolute",
          top: "-55px",
          right: "-5px",
        }}
      />
      <MainTitle>{title}</MainTitle>
      {data.length ? <NichesInformation data={data} /> : <p>Нет информации</p>}
    </>
  ),
};

export { componentsByType };
