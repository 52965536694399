const searchSerializer = (data) => {
  return {
    article: data.id ? data.id : "", // tnved
    label: data.header, // главный текст
    tags: Array.isArray(data.tags) ? data.tags : [], // тэги
    practice: [],
  };
};

export default searchSerializer;
