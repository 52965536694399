import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import "./breadcrumbs.sass";
const MAIN_ROUTE = "/";
const mainPage = window?.CONFIG?.links?.mainPage || process?.env?.REACT_APP_MAIN_PAGE || "/";
export const Breadcrumbs = ({ items = [] }) => {
  return (
    <div className="row">
      <div className="breadcrumb-box">
        <ul className="breadcrumb-row">
          <li className="breadcrumb-item">
            <a className="breadcrumb-link" href={mainPage}>
              Главная
            </a>
          </li>
          <li className="breadcrumb-item">
            <Link
              className={cn("breadcrumb-link", {
                "breadcrumb-link_active": items.length === 0,
              })}
              to={MAIN_ROUTE}
            >
              Поиск рынков сбыта
            </Link>
          </li>
          {items.map((item, index) => (
            <li key={index} className="breadcrumb-item">
              {item.href ? (
                <Link
                  className={`breadcrumb-link ${
                    index === items.length - 1 ? "breadcrumb-link_active" : ""
                  }`}
                  to={`${item.href}`}
                >
                  {item.title}
                </Link>
              ) : (
                <span
                  className={`breadcrumb-link ${
                    index === items.length - 1 ? "breadcrumb-link_active" : ""
                  }`}
                >
                  {item.title}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
