import React, { memo, useState } from "react";
import api from "../../api";
import "./index.sass";
import HINTS from "../../utils/hints";
import Tooltip from "../Tooltip";

const TopQueries = ({ updateQueryText, isOn }) => {
  const [data, setData] = useState(undefined);
  const handleClick = (e, item) => {
    e.preventDefault();
    updateQueryText({
      query: item.tnved_code + ` ${item.tnved_name}`,
      code: item.tnved_code,
      isOption: true,
    });
  };

  if (!data) {
    api.getPopularQueries().then((data) => setData(data));
  }

  if (!data || data.length === 0) return null;

  return (
    <div className={"top-queries"}>
      <Tooltip
        styleTooltip={{
          top: 35,
          zIndex: 0,
          color: "black",
          width: "210px",
        }}
        message={HINTS.searchProcess.text}
        isShow={isOn}
        arrowSize="big"
        maxWidth="210"
        styleArrow={{
          position: "absolute",
          top: "-75px",
          right: "-130px",
          transform: "rotate(-1deg)",
        }}
      />
      <p className="top-queries__title">Популярные запросы</p>
      {data.map((item, index) => (
        <span onClick={(e) => handleClick(e, item)} key={index} className={"top-queries__item"}>
          <div className={"top-queries__code"}>{item.tnved_code}</div>
          <div className={"top-queries__name"}>{item.tnved_name}</div>
        </span>
      ))}
    </div>
  );
};

export default memo(TopQueries);
