import React, { useState, memo } from "react";
import SmoothCollapse from "react-smooth-collapse";

const Describe = () => {
  const [isExpand, setExpand] = useState(false);

  return (
    <div className="search-describe">
      <p
        role="presentation"
        className="search-describe__title"
        onClick={() => setExpand((prevValue) => !prevValue)}
      >
        Механизм работы сервиса «Поиск рынков сбыта»
        <span> &#40;нажмите, чтобы прочитать&#41;</span>
      </p>
      <SmoothCollapse heightTransition="0.5s ease" expanded={isExpand}>
        <p>
          Поиск рынков сбыта (Навигатор экспортера) – уникальный сервис, позволяющий текущим и
          потенциальным экспортерам получить детальное представление о странах – перспективных
          рынках сбыта для конкретного товара российского происхождения, включая перечень
          потенциальных торговых партнеров.
        </p>
        <p>Механизм работы сервиса состоит в следующем:</p>
        <p>
          <b>Этап 1.</b> Подбор кода ТН ВЭД для экспортного товара.
        </p>
        <p>
          Сервис позволяет подобрать код ТН ВЭД для продукции по ее названию или, в отдельных
          случаях, описанию. Сервис работает для 6-значных кодов ТН ВЭД.
        </p>
        <p>
          <b>Этап 2.</b> Определение рейтинга потенциальных стран экспорта для товара.
        </p>
        <p>Рейтинг строится с учетом показателей 2 типов:</p>
        <ol>
          <li>
            Показатели, характеризующие развитие импорта выбранного товара в отдельные страны (такие
            как объем и динамика импорта товара в страну, объем и динамика экспорта России товара в
            страну, концентрация стран-поставщиков в импорте товара страной, тарифные и нетарифные
            барьеры и запреты при импорте товара в страну, наличие экспортного потенциала выбранного
            товара для экспорта из России).
          </li>
          <li>
            Показатели, характеризующие общую привлекательность страны для развития российских
            поставок (такие как транспортная доступность, прогнозные темпы роста ВВП и импорта
            страны, время и стоимость прохождения таможенных процедур при импорте по данным ренкинга
            Doing Business Всемирного Банка).
          </li>
        </ol>
        <p>
          Рейтинг отражает страны в порядке убывания потенциальной привлекательности для экспортера.
          Рейтинг носит гипотетический и информационный характер. В сервисе реализована возможность
          увидеть все страны с показателями импорта товара, доли России в импорте, среднего
          применяемого тарифа, наличия ниши, наличия барьеров для поставок продукции. При нажатии на
          интересующую страну можно увидеть макроэкономическую информацию, информацию об экспорте и
          импорте выбранного товара, а также перейти на страницу с более подробной информацией,
          доступной на третьем этапе.
        </p>
        <p>
          <b>Этап 3.</b> Генерация отчетов:
        </p>
        <ul>
          <li>странового (с показателями импорта страны из мира и экспорта России в страну);</li>
          <li>
            товарно-странового (с показателями торговли выбранной страны целевой продукцией,
            экспорта России в выбранную страну, со сведениями о наличии барьеров для экспорта
            целевой продукции из России в страну и наличии ниши для экспорта целевого товара из
            России в страну). Товарно-страновой отчет содержит список потенциальных покупателей
            продукции в выбранной стране, составленный с использованием авторитетных международных
            баз данных и бизнес-справочников.
          </li>
        </ul>
      </SmoothCollapse>
    </div>
  );
};

export default memo(Describe);
