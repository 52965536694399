import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";

import Icons from "../../layout/Icons";

import styles from "./tooltip.module.scss";

const Arrow = (styleArrow) => ({
  big: <Icons.ArrowBig styleArrow={styleArrow} />,
  middle: <Icons.ArrowMiddle styleArrow={styleArrow} />,
  small: <Icons.ArrowSmall styleArrow={styleArrow} />,
});

const Tooltip = ({ arrowSize, message, isShow, maxWidth, styleArrow, styleTooltip }) => {
  const getArrow = useMemo(() => Arrow(styleArrow)[arrowSize], [styleArrow, arrowSize]);

  if (!isShow) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        ...styleTooltip,
      }}
      className={styles.root}
    >
      <span className={styles.badge}>i</span>
      {getArrow}
      <p style={{ maxWidth: `${maxWidth}px` }} className={styles.message}>
        {message}
      </p>
    </div>
  );
};

Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
  isHide: PropTypes.bool,
  maxWidth: PropTypes.string,
  arrowSize: PropTypes.string,
  styleTooltip: PropTypes.shape({}),
  styleArrow: PropTypes.shape({}),
};

Tooltip.defaultProps = {
  styleTooltip: {},
  styleArrow: {},
  isShow: true,
  maxWidth: "228",
  arrowSize: "small",
};

export default memo(Tooltip);
