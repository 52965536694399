import React, { memo } from "react";

import styles from "./InfoBlock.module.scss";

const linksData = [
  {
    link: "http://stat.customs.ru",
    text: "ФТС",
  },
  {
    link: "http://rosstat.gov.ru",
    text: "Росстат",
  },
  {
    link: "http://comtrade.un.org",
    text: "Comtrade",
  },
  {
    link: "http://www.worldbank.org",
    text: "Мировой банк",
  },
  {
    link: "http://www.imf.org",
    text: "МВФ",
  },
];

const InfoBlock = () => {
  return (
    <div className={styles.infoBlock}>
      При подготовке материала использованы сведения, полученные из источников:
      {linksData.map(({ link, text }) => (
        <a
          key={link}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.linkInfo}
        >
          {text}
        </a>
      ))}
    </div>
  );
};

export default memo(InfoBlock);
