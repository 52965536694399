import { getEnv } from "./utils";

export const PATHS = {
  HOME: "/",
  RESULT: "/result/:q/:code",
  COUNTRY: "/result/:q/:code/country/:id",
  RATING_DETAILS: "/rating_details_:tnved.xlsx",
  CUSTOMERS_SEARCH: "/result/:q/:code/country/:id/customers-search",
  ERROR401: "/401",
  ERROR500: "/500",
};

export const getUrls = (name, params = {}) => {
  if (!PATHS[name]) return null;
  let path = PATHS[name];
  Object.entries(params).forEach(([key, value]) => {
    path = path.replace(`:${key}`, encodeURI(value));
  });
  return path;
};

export const decodeMatchParams = ({ match }) => {
  if (match && match.params) {
    const params = Object.fromEntries(
      Object.entries(match.params).map(([k, v]) => {
        let value;
        try {
          value = decodeURI(v);
        } catch (e) {
          value = v;
        }
        return [k, value];
      })
    );
    return { ...match, params };
  }
  return match;
};

export const CUSTOMERS_SEARCH_IFRAME_BASE_URL = getEnv(
  "CUSTOMERS_SEARCH_IFRAME_BASE_URL",
  window.location.origin
);
