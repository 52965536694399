import React, { useEffect } from "react";
import currencyFormatter from "currency-formatter";

import Hands from "../layout/Icons/Hands";
import ReactTooltip from "../components/ReactTooltip/ReactTooltip";

export const getEnv = (name, value) => process.env[`REACT_APP_${name}`] || value;

export function currencyFormat(value, precision = 0) {
  return currencyFormatter.format(value, {
    thousand: " ",
    decimal: ",",
    precision: precision,
    format: "%v", // %s is the symbol and %v is the value
  });
}

export function numberFormat(value, precision = 0) {
  return currencyFormatter.format(value, {
    thousand: " ",
    decimal: ",",
    precision: precision,
    format: "%v", // %s is the symbol and %v is the value
  });
}

export function percentFormat(value, options) {
  const { precision = 1, signed = true, decimal = ",", thousand = " ", ...extra } = options || {};
  return currencyFormatter.format(value, {
    ...extra,
    thousand,
    decimal,
    precision,
    format: signed
      ? {
          pos: "+%v%",
          neg: "-%v%",
          zero: "+%v%",
        }
      : "%v%",
  });
}

export function useDocumentTitle(title) {
  useEffect(() => {
    document.title = title;
  }, [title]);
}

export function importAll(r) {
  return r.keys().map(r);
}

export const createKeyItem = (item) => {
  let from = item.split("/").slice(0, 3).join("/") + "/";
  let itemTitle = item.split(from).join("").slice(0, -4);
  return itemTitle;
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getCookie = (name) => {
  const matches = document.cookie.match(
    // eslint-disable-next-line
    new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const getAccessToken = ({ clientId }) => {
  return getCookie(`${clientId}_at`) || getCookie("act");
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const makeCancelable = (promise) => {
  let rejectFn;

  const wrappedPromise = new Promise((resolve, reject) => {
    rejectFn = reject;

    Promise.resolve(promise).then(resolve).catch(reject);
  });

  wrappedPromise.cancel = () => {
    rejectFn({ canceled: true });
  };

  return wrappedPromise;
};

export const isEmpty = (v) => v === undefined || v === null;

const STYLE_BY_KEY_COMPANIES = {
  none: {
    tooltip:
      "Для данной страны поиск потенциального покупателя не доступен по причине отсутствия " +
      'в базах данных АО "РЭЦ" достаточных сведений о юридических лицах в данной стране',
    isIconHands: false,
  },
  many: {
    tooltip:
      "Для данной страны доступен поиск потенциального покупателя по широкому кругу иностранных партнеров",
    fill: "green",
    width: 32,
    height: 32,
    heightContainer: 28,
    isIconHands: true,
  },
  few: {
    tooltip:
      "Для данной страны доступен поиск потенциального покупателя по ограниченному кругу иностранных партнеров",
    fill: "blue",
    width: 24,
    height: 24,
    heightContainer: 20,
    isIconHands: true,
  },
};

export const getIconHandsByCountryCompanies = (companies = "none", customStyle = {}) => {
  const handsStyle = { ...STYLE_BY_KEY_COMPANIES[companies], ...customStyle };

  return (
    <ReactTooltip
      style={{
        cursor: "pointer",
        height: handsStyle.heightContainer,
      }}
      place="left"
      borderColor="gray"
      text={handsStyle.tooltip}
    >
      {handsStyle.isIconHands ? <Hands {...handsStyle} /> : <span>—</span>}
    </ReactTooltip>
  );
};
