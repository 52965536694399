import React, { memo, useMemo } from "react";

import Layout from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs/breadcrumbs";

import RatingExport from "../../components/RatingExport";
import ChartExport from "../../components/ChartExport";
import MapExport from "../../components/MapExport";

const Result = ({
  match: {
    params: { q: query = null, code = null },
  },
  menuConfig = {},
}) => {
  const formattedQuery = useMemo(() => {
    return unescape(query);
  }, [query]);
  if (!query) return null;

  return (
    <Layout title={`${code} - ${formattedQuery}`} menuConfig={menuConfig}>
      <section className="innerpage__search-section">
        <div className="container" style={{ display: "flow-root" }}>
          <Breadcrumbs
            items={[
              {
                href: null,
                title: formattedQuery,
              },
            ]}
          />
        </div>
      </section>
      <section className="rating-export__box">
        <div className="container" style={{ display: "flow-root" }}>
          <div className="row row__rating">
            <RatingExport code={code} query={query} formattedQuery={formattedQuery} />
          </div>
          {/*  end row */}
          <div className="row">
            <div className="map-export">
              <div className="secondary-heading secondary-heading__nocol">
                <h2 className="secondary-heading__label">Картографическая диаграмма</h2>
              </div>
              <h3 style={{ color: "red", paddingLeft: "2em" }}>
                По некоторым товарам статистика экспорта России не публикуется
              </h3>
              <MapExport code={code} />
            </div>
          </div>
          <div className="row">
            <div className="chart-export__container">
              <div className="secondary-heading secondary-heading__nocol">
                <h2 className="secondary-heading__label">
                  Динамика экспорта товара из России, млн $
                </h2>
              </div>
              <ChartExport code={code} />
            </div>
          </div>
          {/* end row*/}
        </div>
        {/* end container*/}
      </section>
    </Layout>
  );
};

export default memo(Result);
