import React, { PureComponent } from "react";
import { Dropdown } from "elk-uikit";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import connect from "storeon/react/connect";

import api from "../../api";

import InfoBlock from "../InfoBlock";
import Hint from "../Tooltip";
import hints from "../../utils/hints";

import "./chart-export.sass";

const { diagram } = hints;

const CustomTooltip = (props) => {
  const { active, payload, label } = props;
  if (active && payload && payload[0]) {
    const { pv, rUv: total } = payload[0].payload;
    return (
      <div className="custom-tooltip">
        <p>Данные за {label} год</p>
        <p>
          Экспорт товара из России &nbsp;
          <span>{(total / 1000000).toFixed(1).replace(".", ",")} млн $</span>
        </p>
        {pv ? (
          <p>
            Доля выбранной страны экспорте России товара{" "}
            <span>{((pv / total) * 100).toFixed(1).replace(".", ",")} %</span>{" "}
            <span>{(pv / 1000000).toFixed(1).replace(".", ",")} млн $</span>
          </p>
        ) : null}
      </div>
    );
  }
  return null;
};

class ChartExport extends PureComponent {
  state = {
    data: [],
    optionCountry: [],
    query: "",
    country: "",
    rus: null,
  };

  async componentDidMount() {
    const countries = await api.getListCountries();
    this.setState({
      optionCountry: [
        { label: "Без страны", value: "" },
        ...countries.map(({ name_ru: label, alpha2: value }) => ({
          label,
          value,
        })),
      ],
    });
    this.getRusProductExport();
  }

  componentDidUpdate(prevProps) {
    const { code: currentCode } = this.props;
    const { code: oldCode } = prevProps;
    if (currentCode !== oldCode) {
      this.getRusProductExport();
    }
  }

  getRusProductExport = async () => {
    const { code } = this.props;
    const data = await api.getProductExport({ tnved: code });
    const arrData = [];
    Object.keys(data).forEach((item) => {
      arrData.push({
        name: item,
        uv: data[item],
        rUv: data[item],
        pv: null,
      });
    });
    this.setState({
      data: [...arrData],
      rus: data,
    });
  };

  getProductImportFromRussia = async (iso) => {
    const { code } = this.props;
    const { data: currentData } = this.state;
    const data = await api.getProductExport({ tnved: code, iso });
    const arrData = [...currentData].map((item) => {
      const key = Number(item.name);
      const pv = data[key] || 0;
      return {
        ...item,
        uv: item.rUv - pv,
        pv: pv,
      };
    });

    this.setState({
      data: [...arrData],
    });
  };

  changeCountry = (label) =>
    this.setState({
      country: label,
    });

  setCountry = (label) => {
    const { optionCountry } = this.state;
    const country = optionCountry.find((obj) => obj.label === label);
    this.setState(
      {
        query: country,
        country: country.label,
      },
      () => {
        country.value && this.getProductImportFromRussia(country.value);
      }
    );
  };

  render() {
    const { data, optionCountry, country, query } = this.state;
    const { hint } = this.props;

    return (
      <div className="chart-export__box">
        <div className="inner-select__box">
          <Hint
            isShow={hint.isOn}
            message={diagram.text}
            arrowSize="middle"
            maxWidth="256"
            styleTooltip={{
              top: "-15px",
              right: "-402px",
            }}
            styleArrow={{
              position: "absolute",
              top: "21px",
              left: "-123px",
              transform: "scale(1, -1) rotate(65deg)",
            }}
          />
          <div className="inner-select__box-title">Выбрать страну для сравнения</div>
          <Dropdown
            variants={optionCountry}
            onChange={this.changeCountry}
            onSelect={this.setCountry}
            uniqueKey="value"
            label="Начните вводить страну"
            value={country}
            selectedKeyName="label"
            disableLabelAnimation
          />
        </div>
        {data.length > 0 && (
          <ResponsiveContainer width="100%" height={500}>
            <ComposedChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid vertical={false} />
              <XAxis dataKey="name" stroke="#000" />
              <YAxis
                type="number"
                stroke="#000"
                strokeWidth="0px"
                width={50}
                tickFormatter={(item) => `${(item / 1000000).toFixed(1).replace(".", ",")}`}
              />
              <Legend />
              {query && query.value !== "" ? (
                <Bar
                  name={`Доля страны ${query.label} в общем экспорте товара из России, млн $`}
                  barSize={100}
                  dataKey="pv"
                  stackId="a"
                  fill="#E9D17F"
                />
              ) : null}
              <Bar
                name={
                  query && query.value !== ""
                    ? `Экспорт товара из России (за исключением экспорта страны ${query.label}), млн $`
                    : `Экспорт товара из России, млн $`
                }
                barSize={100}
                dataKey="uv"
                stackId="a"
                fill="#6177FF"
                barCategoryGap="10%"
              />
              <Tooltip
                animationEasing="linear"
                animationDuration={300}
                animationBegin={1000}
                content={<CustomTooltip />}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
        <InfoBlock />
      </div>
    );
  }
}

export default connect("hint", ChartExport);
