const barrierSerializer = (data) => {
  const {
    active: barrierActive,
    adopted_not_in_force: barrierAdoptedNotInForce,
    investigation: barrierInvestigation,
    all: barrierAll,
  } = data;
  return {
    barrierActive,
    barrierAdoptedNotInForce,
    barrierInvestigation,
    barrierAll,
  };
};

export default barrierSerializer;
