import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";

import DocumentsLayout from "./VariantsLayout/Alternate";
import DefaultLayout from "./VariantsLayout/Default";
import { dataPropType, layoutTypePropType } from "./helpers/propTypesHelpers";

function checkIfBrowserIE() {
  if (/* @cc_on!@ */ false || !!document.documentMode) return true;
  return false;
}

const AutoCompleteVariants = (props) => {
  const {
    fullData,
    dataSource,
    onSelect,
    // showAllHandler,
    clearInput,
    textToHighLight,
    index,
    arrayOfProps,
    layoutType,
    isMoreButton,
    isShowMore,
    inputRef,
    showMore,
    elementInItem, // for navigator project
    rightElementInItem, // for navigator project
    exceptionsIdShowingList, // for navigator project
    hideList, // for navigator project
  } = props;

  const onClickItem = useCallback(
    (event, item) => {
      onSelect(item);
      inputRef.current.focus();
      inputRef.current.blur();
    },
    [onSelect, inputRef]
  );

  const onMouseLeaveHandler = (event) => {
    const relatedTarget = checkIfBrowserIE() ? document.activeElement : event.relatedTarget;
    const idRelatedTarget = relatedTarget.id;
    const isException =
      exceptionsIdShowingList &&
      idRelatedTarget &&
      exceptionsIdShowingList.includes(idRelatedTarget);
    if (isException) return;
    inputRef.current.blur();
  };

  const onEnterBlur = () => {
    clearInput();
    inputRef.current.blur();
  };

  if (layoutType === "alternate") {
    return (
      <DocumentsLayout
        data={dataSource}
        onClickItem={onClickItem}
        clearInput={clearInput}
        // showAllHandler={showAllHandler}
        isShowMore={isShowMore}
      />
    );
  }

  return (
    dataSource.length > 0 && (
      <DefaultLayout
        dataSource={dataSource}
        onClickItem={onClickItem}
        textToHighLight={textToHighLight}
        index={index}
        arrayOfProps={arrayOfProps}
        fullData={fullData}
        // showAllHandler={showAllHandler}
        showMore={showMore}
        isMoreButton={isMoreButton}
        isShowMore={isShowMore}
        elementInItem={elementInItem}
        onMouseLeave={hideList && onMouseLeaveHandler} // for navigator project
        onEnterBlur={onEnterBlur}
        rightElementInItem={rightElementInItem}
      />
    )
  );
};

AutoCompleteVariants.propTypes = {
  fullData: dataPropType.isRequired,
  dataSource: dataPropType.isRequired,
  arrayOfProps: PropTypes.arrayOf(PropTypes.string),
  toggleVisibility: PropTypes.func,
  onSelect: PropTypes.func,
  // showAllHandler: PropTypes.func,
  showMore: PropTypes.func,
  clearInput: PropTypes.func,
  textToHighLight: PropTypes.string,
  index: PropTypes.number,
  layoutType: layoutTypePropType,
  isMoreButton: PropTypes.bool,
  isShowMore: PropTypes.bool,
  elementInItem: PropTypes.func,
  rightElementInItem: PropTypes.func,
  exceptionsIdShowingList: PropTypes.arrayOf(PropTypes.any),
  hideList: PropTypes.bool,
};

AutoCompleteVariants.defaultProps = {
  arrayOfProps: [""],
  toggleVisibility: () => false,
  onSelect: () => false,
  // showAllHandler: () => false,
  showMore: () => false,
  clearInput: () => false,
  textToHighLight: "",
  index: -1,
  layoutType: "default",
  isMoreButton: false,
  isShowMore: false,
  elementInItem: null,
  rightElementInItem: null,
  exceptionsIdShowingList: null,
  hideList: false,
};

export default memo(AutoCompleteVariants);
