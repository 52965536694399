import React, { memo } from "react";
import PropTypes from "prop-types";

const Info = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="7" y="6.00001" width="2" height="6" rx="1.5" fill={fill} />
    <rect x="7" y="4.00001" width="1" height="1" rx="1.5" fill={fill} />
  </svg>
);

Info.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

Info.defaultProps = {
  width: 15,
  height: 15,
  fill: "#fff",
};

export default memo(Info);
