import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import cn from "classnames";
import connect from "storeon/react/connect";

import { getUrls } from "../../const";
import api from "../../api";
import Modal from "../../layout/Modal";

import TopQueries from "../TopQueries";

import SearchInput from "./SearchInput";

import "./search-navigator.sass";

const NOT_FOUND = "Ничего не найдено";

class SearchNavigator extends Component {
  constructor(props) {
    super(props);
    this.inputWrapperRef = React.createRef();
    this.state = {
      cacheExter: {},
      isAdvancedSearch: false,
      query: "",
      code: "",
      isDisabled: true,
      isOpenModal: false,
      codeTooltip: "",
      tooltipContent: "",
      tooltipContentList: [],
      tooltipCode: "",
      tooltipQuery: "",
      tnvedLength: null,
      isAccountingMyCountry: false,
      subject: null,
      // subjects: [
      //   {value: "Республика Адыгея", label: "Республика Адыгея"},
      //   {value: "Республика Алтай", label: "Республика Алтай"},
      //   {value: "Республика Башкортостан", label: "Республика Башкортостан"},
      //   {value: "Бурятия", label: "Бурятия"},
      // ]
    };
  }

  componentDidMount() {
    const { query, code } = this.props;
    this.updateQueryText({
      isOption: query && code,
      query,
      code,
    });
  }

  toggleAdvancedSearch = () => {
    const { isAdvancedSearch } = this.state;
    this.setState({
      isAdvancedSearch: !isAdvancedSearch,
    });
  };

  sendFeedback = (value, code, search_method) => {
    (async () => await api.sendFeedback({ id: code, search_method, value }))();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { history } = this.props;
    const { query, code } = this.state;
    const queryWithoutCode = query
      .split(code)[1]
      .substr(1)
      .replace(/\//g, "%2F")
      .replace(/%/g, "%25");
    history.push({
      pathname: getUrls("RESULT", { q: queryWithoutCode, code }),
    });
    this.sendFeedback(queryWithoutCode, code, "full_search");
  };

  updateQueryText = ({ query, code = "", isOption = false, callback = () => {} }) => {
    const { isDisabled } = this.state;

    this.setState(
      {
        query,
        code,
        isDisabled: !isOption && isDisabled,
      },
      callback
    );
  };

  matchBeforeClick = ({ option, value }) => {
    const found = option.find((item) => item.article === value);

    if (found) {
      this.setState({
        code: found.article,
        found: found,
        isDisabled: false,
      });
    }
  };

  handleClickButton = () => {
    const { found } = this.state;

    if (found) {
      this.setState({
        query: found.value,
        code: found.article,
      });
    }
  };

  toggleOpenModal = () => {
    const { isOpenModal } = this.state;
    this.setState({
      isOpenModal: !isOpenModal,
    });
  };

  handleTnved = (e) => {
    this.setState({
      tnvedLength: e.target.value,
    });
  };

  openTooltip = async (targetRef, code, tooltipQuery) => {
    const { query, cacheExter } = this.state;
    const newTooltip = `${query}:${code}`;

    if (cacheExter[code]) {
      const res = cacheExter[code]["tooltipContent"];
      this.setState(
        {
          currentTooltip: newTooltip,
          tooltipContent: res.join("\n") || NOT_FOUND,
          tooltipContentList: res,
          tooltipCode: code,
          tooltipQuery,
        },
        () => {
          ReactTooltip.show(targetRef);
          ReactTooltip.rebuild();
        }
      );
      return false;
    } else {
      this.setState({
        currentTooltip: newTooltip,
      });
    }
  };

  loadTooltips = async (items) => {
    const codes = items.map((item) => item.article);
    const res = await api.getExtra({ code: codes });

    const { cacheExter } = this.state;
    codes.forEach((code) => {
      const tooltipContent = res.filter((item) => item.indexOf(code) + 1);
      if (!cacheExter[code]) {
        cacheExter[code] = {
          tooltipContent,
        };
      }
    });
    this.setState({
      cacheExter: { ...cacheExter },
    });
  };

  closeTooltip = (targetRef) => {
    setTimeout(function () {
      ReactTooltip.hide(targetRef);
    }, 1000);
  };

  handleAccountingMyCountry = () => {
    const { isAccountingMyCountry } = this.state;
    this.setState({
      isAccountingMyCountry: !isAccountingMyCountry,
    });
  };

  handleSubject = ({ value }) => {
    this.setState({
      subject: value,
    });
  };

  clearQuery = () => {
    this.setState({
      query: "",
      code: "",
    });
  };

  render() {
    const {
      // isAdvancedSearch,
      query,
      isDisabled,
      isOpenModal,
      code,
      tnvedLength,
      isAccountingMyCountry,
      subject,
      // subjects,
      tooltipContent,
      tooltipContentList,
      tooltipCode,
      tooltipQuery,
    } = this.state;
    const {
      isShadow,
      isShowTop,
      isPainted,
      showCloseLeft = true,
      autoFocus = false,
      hint: { isOn },
    } = this.props;

    let position = "top";
    if (
      this.inputWrapperRef.current &&
      window.innerWidth - this.inputWrapperRef.current.getBoundingClientRect().right < 200
    ) {
      position = "left";
    }

    return (
      <div
        className={cn("search-panel-box", {
          "search-panel-box-shadow": isShadow,
        })}
      >
        <form onSubmit={this.handleSubmit} action="#" className="search-panel-form">
          <div ref={this.inputWrapperRef}>
            <SearchInput
              loadTooltips={this.loadTooltips}
              updateQueryText={this.updateQueryText}
              matchBeforeClick={this.matchBeforeClick}
              value={query}
              code={code}
              autoFocus={autoFocus}
              isDisabled={isDisabled}
              filter={{
                tnvedLength,
                isAccountingMyCountry,
                subject,
              }}
              onClear={this.clearQuery}
              openTooltip={this.openTooltip}
              closeTooltip={this.closeTooltip}
              handleClickButton={this.handleClickButton}
              sendFeedback={this.sendFeedback}
              isPainted={isPainted}
              showCloseLeft={showCloseLeft}
            />
          </div>
          {tooltipContent ? (
            <ReactTooltip
              id="more-info"
              type="light"
              clickable={true}
              eventOff={"click"}
              place={position}
              delayHide={500}
              effect="solid"
            >
              <div id="more-info-wrapper">
                <div style={{ maxWidth: "400px" }} id="more-info-tooltip">
                  {tooltipContent.length > 200
                    ? `${tooltipContent.slice(0, 201)}}...`
                    : tooltipContent}
                </div>
                {tooltipContent.length > 200 ? (
                  <button
                    onClick={this.toggleOpenModal}
                    type="button"
                    className="btn-secondary filter-btn filter-btn--tooltip"
                  >
                    Раскрыть полностью
                  </button>
                ) : null}
              </div>
            </ReactTooltip>
          ) : null}
          {/*<div className="filter-btn-wrap">*/}
          {/*  {isAdvancedSearch ? (*/}
          {/*    <button*/}
          {/*      onClick={this.toggleAdvancedSearch}*/}
          {/*      type="button"*/}
          {/*      className="btn-secondary filter-btn"*/}
          {/*    >*/}
          {/*      <FilterHideIcon className="filter-btn-icon" />*/}
          {/*      <span>Скрыть</span>*/}
          {/*    </button>*/}
          {/*  ) : (*/}
          {/*      <button onClick={this.toggleAdvancedSearch} type="button" className="btn-secondary filter-btn">*/}
          {/*        <FilterIcon className="filter-btn-icon" />*/}
          {/*        <span>Уточнить поиск</span>*/}
          {/*      </button>*/}
          {/*    )}*/}
          {/*</div>*/}
          {/*{isAdvancedSearch ? (*/}
          {/*  <>*/}
          {/*    <div className="more-search-selectlabel">*/}
          {/*      Субъект РФ, из которого планируется экспорт*/}
          {/*    </div>*/}
          {/*    <div className="more-search-box">*/}
          {/*      <div className="more-search-select">*/}
          {/*        <Select*/}
          {/*          options={subjects}*/}
          {/*          classNamePrefix="more-select"*/}
          {/*          className="more-select-container"*/}
          {/*          placeholder={"Введите субъект РФ"}*/}
          {/*          onChange={this.handleSubject}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*      <div className="main_radio-checkbox_box">*/}
          {/*        <span className="main_radio-checkbox__label">Выбор по знакам ТН ВЭД:</span>*/}
          {/*        <div className="main-radio-checkbox__list">*/}
          {/*          {[6, 10].map(item => (*/}
          {/*              <RadioCheckbox*/}
          {/*                  key={item}*/}
          {/*                  label={`${item} знаков`}*/}
          {/*                  value={item}*/}
          {/*                  onChange={this.handleTnved}*/}
          {/*                  name={"charcode"}*/}
          {/*                  selected={tnvedLength}*/}
          {/*              />*/}
          {/*          ))}*/}
          {/*        </div>*/}
          {/*        <Checkbox*/}
          {/*          name={"accountingMyCountry"}*/}
          {/*          label={"Учитывать данные моей организации"}*/}
          {/*          checked={isAccountingMyCountry}*/}
          {/*          onChange={this.handleAccountingMyCountry}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </>*/}
          {/*) : null}*/}
        </form>
        <Modal isOpen={isOpenModal} closeModal={this.toggleOpenModal}>
          <div className="modal-header">
            <div>
              <div className="modal-title">
                <span>({tooltipCode})</span> {tooltipQuery}
              </div>
              {/*<div className="modal-subtitle">Позиция ОКПД 2</div>*/}
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-body__wrapper">
              {/* {tooltipContent} */}
              {tooltipContentList.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </div>
        </Modal>
        {isShowTop && (
          <div className={"search-panel-tooltip"}>
            <TopQueries updateQueryText={this.updateQueryText} isOn={isOn} />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(connect("user", "hint", SearchNavigator));
