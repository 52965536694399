import React, { Component, memo } from "react";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import { withRouter } from "react-router-dom";
import connect from "storeon/react/connect";
// import { PATHS } from '../../const';
import api from "../../api";
import ReactTooltip from "react-tooltip";
import worldMap from "./world-110m.json";
import RadioCheckbox from "../../layout/RadioCheckbox";
import CountryFlag from "../../layout/CountryFlag";
import Loader from "../../layout/Loader";
import "./map-export.sass";
import Hint from "../Tooltip";
import HINTS from "../../utils/hints";
import { currencyFormat, percentFormat } from "../../utils";

const { mapCountry } = HINTS;

const interpolate = require("color-interpolate");

const COLORS = {
  empty: "#DCDCDC",
  stroke: "#FFFFFF",
  pressed: "#E42",
  min: "#EEEFFF",
  max: "#011280",
};
const LEGEND_GRADIENT = `linear-gradient(89.54deg, ${COLORS.max} 2.14%, ${COLORS.min} 100.01%)`;

let colormap = interpolate([COLORS.min, COLORS.max]);

const formats = {
  currency: function ({ value, unit = " $" }) {
    return (
      <span>
        {currencyFormat(value)}
        {unit}
      </span>
    );
  },
  percent: function ({ value }) {
    return <span>{percentFormat(value * 100, { precision: 2, signed: false })}</span>;
  },
};

class MapExport extends Component {
  state = {
    isLoad: true,
    data: {},
    max: 0,
    min: 0,
    zoom: 0.9,
    selectCountry: null,
    selectType: "russian_export",
    selectFormat: "currency",
  };

  componentDidMount() {
    this.getMap();
  }

  componentDidUpdate(prevProps) {
    const { code: currentCode } = this.props;
    const { code: oldCode } = prevProps;
    if (currentCode !== oldCode) {
      this.getMap();
    }
  }

  getMap = async () => {
    const { code } = this.props;
    const { selectType } = this.state;
    this.setState({
      isLoad: true,
    });
    const data = await api.getMap({ tnved: code, type: selectType });
    this.setState({
      isLoad: false,
      data: data.countries,
      max: data.maximum,
      min: data.minimum,
    });
  };

  handleZoomIn = () => {
    const { zoom } = this.state;
    if (zoom >= 4) return;
    this.setState({
      zoom: zoom * 1.1,
    });
  };

  handleZoomOut = () => {
    const { zoom } = this.state;
    if (zoom <= 0.9) return;
    this.setState({
      zoom: zoom / 1.1,
    });
  };

  handleZoomEnd = (position) => {
    this.setState({
      zoom: position.zoom,
    });
  };

  setTooltipContent = (country) => {
    this.setState({
      selectCountry: country,
    });
  };

  getColor = (obj, defaultColor = COLORS.empty) => {
    if (!obj) return defaultColor;
    const { value } = obj;
    let {
      max,
      // min
    } = this.state;
    max = max === 0 ? 1 : max;
    // min = min >= max ? 0 : min;
    try {
      return colormap(value / max);
    } catch (error) {
      return COLORS.min;
    }
  };

  handleSelectType = (selectType, selectFormat = "currency") => {
    this.setState(
      {
        selectType,
        selectFormat,
      },
      () => {
        this.getMap();
      }
    );
  };

  render() {
    const { data, zoom, selectCountry, max, min, selectType, selectFormat, isLoad } = this.state;
    const { hint } = this.props;
    const {
      handleZoomIn,
      handleZoomOut,
      handleZoomEnd,
      setTooltipContent,
      handleSelectType,
    } = this;
    const FormatFunction = formats[selectFormat];
    return (
      <div className="map-export__box-wrapper" style={{ width: "100%", backgroundColor: "#fff" }}>
        <Hint
          isShow={hint.isOn}
          message={mapCountry.text}
          arrowSize="middle"
          maxWidth="228"
          styleTooltip={{
            top: "-100px",
            left: "60vw",
          }}
          styleArrow={{
            position: "absolute",
            top: "21px",
            left: "-123px",
          }}
        />
        <div className="map-export__checkbox_list">
          <span className="map-export__checkbox_item">
            <RadioCheckbox
              onChange={() => handleSelectType("russian_export")}
              label={"Экспорт России"}
              name={"map-export__checkbox-item"}
              value={"russian_export"}
              selected={selectType}
            />
            <span data-tip data-for="export-from-russia" className="table__tooltip_question"></span>
            <ReactTooltip id="export-from-russia" type="light" className="table__tooltip_box">
              <p>Страны по убыванию экспорта товара из России</p>
            </ReactTooltip>
          </span>
          <span className="map-export__checkbox_item">
            <RadioCheckbox
              onChange={() => handleSelectType("full_import")}
              label={"Импорт из всех стран"}
              name={"map-export__checkbox-item"}
              value={"full_import"}
              selected={selectType}
            />
            <span
              data-tip
              data-for="import-from-all-country"
              className="table__tooltip_question"
            ></span>
            <ReactTooltip id="import-from-all-country" type="light" className="table__tooltip_box">
              <p>Страны по убыванию импорта товара</p>
            </ReactTooltip>
          </span>
          <span className="map-export__checkbox_item">
            <RadioCheckbox
              onChange={() => handleSelectType("russian_import_part", "percent")}
              label={"Доля России в импорте страны"}
              name={"map-export__checkbox-item"}
              value={"russian_import_part"}
              selected={selectType}
            />
            <span data-tip data-for="part-import-russia" className="table__tooltip_question"></span>
            <ReactTooltip id="part-import-russia" type="light" className="table__tooltip_box">
              <p>Доля импорта из России в импорте товара страной</p>
            </ReactTooltip>
          </span>
        </div>
        <div
          style={{
            width: "100%",
            height: "auto",
            maxWidth: "1200px",
            margin: "0 auto",
            position: "relative",
          }}
        >
          {isLoad ? (
            <div className={"map-export__loader"}>
              <Loader />
            </div>
          ) : null}
          <ComposableMap
            data-tip=""
            width={800}
            height={400}
            style={{
              width: "100%",
              height: "auto",
            }}
          >
            <ZoomableGroup zoom={zoom} onZoomEnd={handleZoomEnd} center={[0, 5]}>
              <defs>
                <pattern
                  id="noData"
                  fill={COLORS.empty}
                  width="2"
                  height="2"
                  patternUnits="userSpaceOnUse"
                  patternTransform="rotate(30 75 50)"
                >
                  <line stroke={COLORS.empty} strokeWidth="2" y2="2" />
                </pattern>
              </defs>
              <Geographies geography={worldMap} fill={COLORS.empty} stroke={COLORS.stroke}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      style={{
                        default: {
                          fill: this.getColor(data[geo.properties.ISO_A2], "url(#noData)"),
                          outline: "none",
                        },
                        hover: {
                          fill: data[geo.properties.ISO_A2] ? COLORS.max : "url(#noData)",
                          stroke: COLORS.stroke,
                          outline: "none",
                        },
                        pressed: {
                          fill: COLORS.pressed,
                          outline: "none",
                        },
                      }}
                      onMouseEnter={() => {
                        // const { NAME, POP_EST } = geo.properties;
                        const obj = data[geo.properties.ISO_A2] || {};
                        setTooltipContent({
                          ...obj,
                          iso: geo.properties.ISO_A2,
                        });
                      }}
                      onMouseLeave={() => {
                        setTooltipContent(null);
                      }}
                    />
                  ))
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
        </div>
        <ReactTooltip type="light" className={`table__tooltip_box`}>
          {selectCountry && typeof selectCountry.value === "number" ? (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CountryFlag width={100} iso={selectCountry.iso} />
                {/*<img style={{ width: '100px' }} src={countriesIcon[selectCountry.iso]} alt="" />*/}
              </div>
              <div>{selectCountry.name}</div>
              {selectCountry.year ? (
                <div style={{ color: "rgb(200, 200, 200)" }}>{selectCountry.year} год</div>
              ) : null}
              <div style={{ color: "#BE5A5A" }}>
                <FormatFunction value={selectCountry.value} />
              </div>
            </>
          ) : null}
        </ReactTooltip>

        <div className="map-export__controls">
          <button type="button" className="map-export__controls_btn" onClick={handleZoomIn}>
            +
          </button>
          <button type="button" className="map-export__controls_btn" onClick={handleZoomOut}>
            -
          </button>
        </div>

        <div className="map-export__box">
          <div className="map-export__legend" style={{ background: LEGEND_GRADIENT }}>
            <div className="map-export__legend-item-start">
              <FormatFunction value={max} />
            </div>
            <div className="map-export__legend-item-end">
              <FormatFunction value={min} />
            </div>
          </div>
          <div className="map-export__legend-default">
            <svg>
              <rect fill="url(#noData)" />
            </svg>
            <span>Нет данных</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect("hint", memo(MapExport)));
