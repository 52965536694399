import React from "react";
import "./index.sass";

const Sidebar = ({ title = "", back = "", items }) => {
  return (
    <div className="sidebar">
      {back ? (
        <a href={`${back}`} className="sidebar__back">
          Назад
        </a>
      ) : null}
      <div className="sidebar__title">{title}</div>
      <ul className="sidebar-list">
        {items.map((item, index) => {
          const { Link, href, title } = item;
          return (
            <li className="sidebar-list__item" key={index}>
              <Link href={href}>{title}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
