import React, { memo } from "react";
import { BrowserLink } from "../Link";
import "./index.sass";
import ReactTooltip from "react-tooltip";

const Service = ({ id, service: rawService }) => {
  const service = {
    url: "https://www.exportcenter.ru/", // Temporary hard code
    tooltip: 'Переход в каталог услуг на цифровой платформе "Мой экспорт"',
    ...rawService,
  };

  return (
    <div className="service">
      <div className="service__block">
        <div className="service__title">
          {service.name}
          {service.tooltip ? (
            <span>
              <span
                data-tip
                data-for={`service__title_tooltips-${id}`}
                className="table__tooltip_question"
              ></span>
              <ReactTooltip
                id={`service__title_tooltips-${id}`}
                type="light"
                className="table__tooltip_box_warning"
              >
                <p>{service.tooltip}</p>
              </ReactTooltip>
            </span>
          ) : null}
        </div>
        {service.description ? (
          <div className="service__description">{service.description}</div>
        ) : null}
      </div>
      <div className="service__info">
        <div className="service__link-block">
          <BrowserLink target="_blank" href={service.url} modifier="small">
            <div className="btn btn-primary service__link-btn">Перейти</div>
          </BrowserLink>
        </div>
      </div>
    </div>
  );
};

export default memo(Service);
