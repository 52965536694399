import React, { memo } from "react";
import "./index.sass";

const ImportFromRussia = ({ data = [] }) => (
  <>
    {data.map(({ code, description, amount, year }, index) => (
      <div className="sec-table__row" key={index}>
        <div className="sec-table__item">
          <div className="sec-table__item_blue-accent">{code}</div>
        </div>
        <div className="sec-table__item sec-table__item-label">{description}</div>
        <div className="sec-table__item sec-table__item_sm sec-table__item_bdr-r sec-table__item_pr-0">
          <div className="sec-table__item_black-accent sec-table__item_nowrap">
            <span>$ </span>
            {amount}
          </div>
        </div>
        <div className="sec-table__tags-wrapper">
          <div className="sec-table__tags">{year}</div>
        </div>
      </div>
    ))}
  </>
);

export default memo(ImportFromRussia);
