import React from "react";
import { createKeyItem, importAll } from "../../utils";

let countriesIcon = {};
importAll(require.context("../../images/icons/countries/", false, /\.(png|jpe?g|svg)$/)).map(
  (item) => {
    let title = createKeyItem(item);
    let iso3 = title.split(".")[0];
    countriesIcon[iso3] = item;
    return { item, title };
  }
);

const CountryFlag = ({ iso, width = 30, alt = "" }) => {
  const src = countriesIcon[iso.toLowerCase()];
  if (!iso || !src) {
    return <span style={{ width: `${width}px` }}></span>;
  }
  return <img style={{ width: `${width}px` }} src={src} alt={alt} />;
};

export default CountryFlag;
