import React, { Component, memo } from "react";
import ReactTooltip from "react-tooltip";
import connect from "storeon/react/connect";
import { Button } from "elk-uikit";

import { DEBOUNCE_TIME, RADIO, MIN_COUNT_COUNTRY_FOR_HINT } from "./constans";

import api from "../../api";
import { getUrls } from "../../const";
import CountryFlag from "../../layout/CountryFlag";
import RadioCheckbox from "../../layout/RadioCheckbox";
import AboutTable from "../../layout/AboutTable";
import TradeProduct from "../../layout/TradeProduct";
import Loader from "../../layout/Loader";
import { ReactComponent as Success } from "../../images/icons/success.svg";
import { ReactComponent as ChevronDown } from "../../images/icons/chevron-down.svg";
import { ReactComponent as ChevronRight } from "../../images/icons/chevron-right.svg";
import { ReactComponent as ChevronUp } from "../../images/icons/chevron-up.svg";
import HINTS from "../../utils/hints";
import { debounce, getIconHandsByCountryCompanies } from "../../utils/index";

import Tooltip from "../Tooltip";
import AutoComplete from "../AutoComplete";
import SearchNavigator from "../SearchNavigator";

import "./rating-export.sass";

class RatingExport extends Component {
  state = {
    page: 1,
    rowsPerPage: 10,
    data: [],
    filteredData: [],
    openIndex: null,
    country: null,
    sortingType: "scoring_place",
    sortingDirection: false,
    hasNext: false,
    barriersInfo: null,
    isLoad: true,
    searchValueCountry: "",
    autoCompleteCountry: [],
    isEditCode: false,
  };

  componentDidMount() {
    this.getRating();
  }

  componentDidUpdate(prevProps, prevState) {
    const [{ code: currentCode }, { code: oldCode }] = [this.props, prevProps];

    const [{ rowsPerPage: currentRow }, { rowsPerPage: oldRow }] = [this.state, prevState];
    if (currentCode !== oldCode) {
      this.setState({ isEditCode: false });
    }
    if (currentCode !== oldCode || currentRow !== oldRow) {
      this.getRating();
    }
  }

  getRating = async (page = 1) => {
    const { code } = this.props;
    const {
      data,
      sortingType,
      sortingDirection,
      rowsPerPage,
      hasNext: currentHasNext,
    } = this.state;
    this.setState({
      isLoad: true,
      data: page === 1 ? [] : [...data],
      filteredData: page === 1 ? [] : [...data],
      country: null,
      hasNext: page === 1 ? false : currentHasNext,
    });
    const res = await api.getRating({
      tnved: code,
      sort: `${sortingType}${sortingDirection ? " desc" : ""}`,
      page,
      rowsPerPage,
    });
    const { result, has_next: hasNext } = res;
    this.setState({
      data: page === 1 ? [...result] : [...data, ...result],
      autoCompleteCountry: page === 1 ? [...result] : [...data, ...result],
      filteredData: page === 1 ? [...result] : [...data, ...result],
      page,
      hasNext,
      isLoad: false,
    });
  };

  sorting = (param) => {
    const { sortingType, sortingDirection } = this.state;
    this.setState(
      {
        sortingType: param,
        sortingDirection: sortingType === param ? !sortingDirection : true,
      },
      this.getRating
    );
  };

  openMoreInfo = async (index, iso) => {
    // if(!['IN', 'CN'].includes(iso)) return false // заблокируем раскрытие строк для не Индии и не Китая
    const { openIndex } = this.state;
    const { code } = this.props;
    if (index !== openIndex) {
      this.setState({ openIndex: index, country: null });
      const country = await api.getCountryShortInfo({ iso, tnved: code });
      this.setState({ country });
      return true;
    }
    this.setState({
      openIndex: null,
    });
  };

  getBarrier = async (iso) => {
    const { code } = this.props;
    this.setState({
      barriersCounts: null,
    });
    const barriersCounts = await api.getBarrier({ iso, tnved: code });
    this.setState({
      barriersCounts,
    });
  };

  filterCountry = (e) => {
    const { value } = e.target;
    const { data } = this.state;
    const filteredData = data.filter(({ countryRu = "" }) =>
      countryRu.toLowerCase().includes(value.toLowerCase())
    );

    this.setState({
      filteredData,
    });
  };

  searchCountry = debounce(async (trimmedValue, updateListCountry = false) => {
    const { code } = this.props;

    if (!trimmedValue) {
      this.getRating();
    } else {
      const { result } = await api.getRating({
        tnved: code,
        page: 1,
        rowsPerPage: 250,
        country_ru: `%${trimmedValue}%`,
      });

      const data = result.map((i) => {
        const name = i.countryRu.toLowerCase();
        if (name.startsWith(trimmedValue.toLowerCase())) {
          return { ...i, __weight: 10000 };
        } else if (name.split(" ").some((j) => j.startsWith(trimmedValue.toLowerCase()))) {
          return { ...i, __weight: 1000 };
        } else {
          const index = name.indexOf(trimmedValue.toLowerCase());
          return { ...i, __weight: index > -1 ? 1000 - index : 0 };
        }
      });
      data.sort((a, b) => {
        if (a.__weight < b.__weight) {
          return 1;
        }
        if (a.__weight > b.__weight) {
          return -1;
        }
        return 0;
      });

      this.setState({
        autoCompleteCountry: data,
      });

      if (updateListCountry) {
        this.setState({
          filteredData: result,
          isLoad: false,
        });
      }
    }
  }, DEBOUNCE_TIME);

  searchCountryDebounce = (value) => {
    const trimmedValue = value.trim();

    this.setState({
      searchValueCountry: trimmedValue,
    });

    this.searchCountry(trimmedValue);
  };

  handleSelectCountry = (country) => {
    const { countryRu } = country;

    this.setState({
      openIndex: null,
      searchValueCountry: countryRu,
      filteredData: [country],
    });
  };

  handleChangeCode = (e) => {
    this.setState({
      isEditCode: true,
    });
  };

  handleClearCountry = () => {
    const { data, filteredData } = this.state;
    this.setState({
      searchValueCountry: "",
    });

    if (data.length !== filteredData.length) {
      this.getRating();
    }
  };

  handleChangeLimitCountry = (e) => {
    const { value } = e.target;
    this.setState({
      isLoad: true,
      rowsPerPage: Number(value),
    });
  };

  searchCountryHandler = () => {
    const { searchValueCountry } = this.state;

    this.setState({
      isLoad: true,
    });

    this.searchCountry(searchValueCountry, true);
  };

  render() {
    const {
      data,
      rowsPerPage,
      filteredData,
      openIndex,
      country,
      sortingType,
      sortingDirection,
      barriersCounts,
      isLoad,
      isEditCode,
      searchValueCountry,
      autoCompleteCountry,
    } = this.state;

    const {
      query,
      formattedQuery,
      code,
      hint: { isOn },
    } = this.props;

    return (
      <div className="rating-export__list">
        <div className="rating-export__input-wrapper">
          <div className="rating-export__label">
            <h2>Рейтинг потенциальных стран экспорта для товара</h2>
            <small>
              {code} {formattedQuery}{" "}
              {!isEditCode && (
                // eslint-disable-next-line
                <a href="#" onClick={this.handleChangeCode}>
                  изменить
                </a>
              )}
            </small>
          </div>
        </div>
        <div className="rating-export__input-wrapper">
          <div>
            {isEditCode && (
              <SearchNavigator
                autoFocus={true}
                isShowTop={false}
                isPainted={false}
                showCloseLeft={false}
              />
            )}
            <div className="search-panel-box">
              <AutoComplete
                style={{
                  width: "100%",
                  "& div ul": {
                    height: "400px!important",
                  },
                }}
                label="Поиск стран"
                inputValue={searchValueCountry}
                inputOnChange={this.searchCountryDebounce}
                dataSource={autoCompleteCountry}
                onSelect={this.handleSelectCountry}
                arrayOfProps={["countryRu"]}
                clearInput={this.handleClearCountry}
                classNameInput="searchInput"
                elementInInput={
                  <Button
                    disabled={!searchValueCountry}
                    className="selectCountriesBtn"
                    type="submit"
                    variant="secondary"
                    width="200px"
                    onClick={this.searchCountryHandler}
                  >
                    Поиск
                  </Button>
                }
                isPainted={false}
                showCloseLeft
                isBackSideSearch
              />
            </div>
          </div>
          <div className="rating-export__description">
            Рейтинг показывает страны в порядке убывания потенциальной привлекательности для
            экспортера и носит информационный (условный) характер. Рейтинг строится с учетом
            показателей 2 типов:
            <ol style={{ listStylePosition: "inside", margin: 0, padding: 0 }}>
              <li style={{ padding: "5px 0" }}>
                Показатели импортного спроса на товар в стране: объем и динамика импорта, поставки
                из России, концентрация стран-поставщиков, таможенные пошлины, нетарифные барьеры и
                др.
              </li>
              <li style={{ padding: "5px 0" }}>
                Показатели, характеризующие общую привлекательность страны для экспорта из России:
                транспортная доступность, прогнозные темпы роста ВВП и импорта страны и др.
                Недружественным странам присвоен сильный понижающий коэффициент.
              </li>
            </ol>
          </div>
        </div>
        {!isLoad && filteredData.length >= MIN_COUNT_COUNTRY_FOR_HINT && (
          <Tooltip
            styleTooltip={{
              zIndex: 1000,
              top: "60vh",
              left: "55%",
              backgroundColor: "#f0f3fd",
            }}
            message={HINTS.tableSelectCountry.text}
            isShow={isOn && !country}
            arrowSize="middle"
            maxWidth="361"
            styleArrow={{
              position: "absolute",
              top: "20px",
              left: "-118px",
              transform: "rotate(-1deg)",
            }}
          />
        )}
        <div className="rating-export__table">
          <div className="rating-export__table-wrapper">
            <div className="table__row table__row--header">
              <div className="table__item-wrapper table__head">
                <div className="table__col2 table__country--header">
                  <div
                    onClick={() => this.sorting("name_ru")}
                    className={`table__head_item table__head_item_sort ${
                      sortingType === "name_ru"
                        ? sortingDirection
                          ? "active-top"
                          : "active-bottom"
                        : ""
                    }`}
                  >
                    Страна
                  </div>
                </div>

                <div className="table__col2 table__value-import">
                  <div
                    onClick={() => this.sorting("comtrade_import2")}
                    className={`table__head_item table__head_item_sort ${
                      sortingType === "comtrade_import2"
                        ? sortingDirection
                          ? "active-top"
                          : "active-bottom"
                        : ""
                    }`}
                  >
                    <span
                      data-tip
                      data-for="table__tooltip-value-import"
                      className="table__tooltip_question"
                    />
                    <ReactTooltip
                      id="table__tooltip-value-import"
                      type="light"
                      className="table__tooltip_box"
                    >
                      <p>Импорт выбранного товара страной</p>
                      <p>Источник: база данных торговой статистики ООН UN Comtrade</p>
                    </ReactTooltip>
                    Импорт товара страной
                  </div>
                </div>

                <div className="table__col2 table__part-import">
                  <div
                    onClick={() => this.sorting("import_from_russia_part")}
                    className={`table__head_item table__head_item_sort ${
                      sortingType === "import_from_russia_part"
                        ? sortingDirection
                          ? "active-top"
                          : "active-bottom"
                        : ""
                    }`}
                  >
                    <span
                      data-tip
                      data-for="table__tooltip-part-import"
                      className="table__tooltip_question"
                    />
                    <ReactTooltip
                      id="table__tooltip-part-import"
                      type="light"
                      className="table__tooltip_box"
                    >
                      <p>Оценка доли России в импорте товара страной</p>
                      <p>Источники: база данных торговой статистики ООН UN Comtrade, ФТС России</p>
                    </ReactTooltip>
                    Доля России в импорте
                  </div>
                </div>

                <div className="table__col2 table__average-rate">
                  <div
                    onClick={() => this.sorting("applied_tariff")}
                    className={`table__head_item table__head_item_sort ${
                      sortingType === "applied_tariff"
                        ? sortingDirection
                          ? "active-top"
                          : "active-bottom"
                        : ""
                    }`}
                  >
                    <span
                      data-tip
                      data-for="table__tooltip-average-rate"
                      className="table__tooltip_question"
                    />
                    <ReactTooltip
                      id="table__tooltip-average-rate"
                      type="light"
                      className="table__tooltip_box"
                    >
                      <p>
                        Средняя ставка применяемого таможенного тарифа при импорте выбранного товара
                        страной.
                      </p>
                      <p>Источник: внутренняя база тарифных барьеров РЭЦ</p>
                    </ReactTooltip>
                    Средний применяемый тариф
                  </div>
                </div>
                <div className="table__col1 table__niche">
                  <div
                    onClick={() => this.sorting("niches_number")}
                    className={`table__head_item table__head_item_sort ${
                      sortingType === "niches_number"
                        ? sortingDirection
                          ? "active-top"
                          : "active-bottom"
                        : ""
                    }`}
                  >
                    <span
                      data-tip
                      data-for="table__tooltip-niche"
                      className="table__tooltip_question"
                    />
                    <ReactTooltip
                      id="table__tooltip-niche"
                      type="light"
                      className="table__tooltip_box"
                    >
                      <p>
                        Наличие экспортной ниши в стране для выбранного товара по экспертной оценке
                        РЭЦ.
                      </p>
                      <p>Источник: внутренняя база экспортных ниш РЭЦ</p>
                    </ReactTooltip>
                    Наличие ниши
                  </div>
                </div>
                <div className="table__col1 table__barrier">
                  <div
                    onClick={() => this.sorting("barriers_number")}
                    className={`table__head_item table__head_item_sort ${
                      sortingType === "barriers_number"
                        ? sortingDirection
                          ? "active-top"
                          : "active-bottom"
                        : ""
                    }`}
                  >
                    <span
                      data-tip
                      data-for="table__tooltip-barrier"
                      className="table__tooltip_question"
                    />
                    <ReactTooltip
                      id="table__tooltip-barrier"
                      type="light"
                      className="table__tooltip_box"
                    >
                      <p>
                        Количество нетарифных экспортных барьеров, действующих в стране для
                        выбранного товара.
                      </p>
                      <p>Источник: внутренняя база нетарифных барьеров РЭЦ</p>
                    </ReactTooltip>
                    Барьер
                  </div>
                </div>
                <div className="table__col1 table__rating">
                  <div
                    onClick={() => this.sorting("scoring_place")}
                    className={`table__head_item table__head_item_sort ${
                      sortingType === "scoring_place"
                        ? sortingDirection
                          ? "active-top"
                          : "active-bottom"
                        : ""
                    }`}
                  >
                    <span
                      data-tip
                      data-for="table__tooltip-rating"
                      className="table__tooltip_question"
                    />
                    <ReactTooltip
                      id="table__tooltip-rating"
                      type="light"
                      className="table__tooltip_box"
                    >
                      <p>Рейтинг потенциальных стран экспорта для товара.</p>
                    </ReactTooltip>
                    Место в рейтинге
                  </div>
                </div>
                <div className="table__col1 table__col1--last">
                  <span>&nbsp;</span>
                </div>
              </div>
            </div>
            {data.length === filteredData.length ? (
              <div className="btn-wrap__center">
                Показать
                {RADIO.map(({ value, label }) => (
                  <RadioCheckbox
                    key={label}
                    onChange={this.handleChangeLimitCountry}
                    selected={rowsPerPage}
                    value={value}
                    label={label}
                  />
                ))}
              </div>
            ) : null}
            {!isLoad &&
              filteredData.map(
                (
                  {
                    iso,
                    countryRu,
                    importVolume,
                    importVolumeYear,
                    importVolumeFormated,
                    importFromRussia,
                    importFromRussiaYear,
                    importFromRussiaPercent,
                    avgAppliedTariffPercent,
                    nichesCount,
                    barriersCount,
                    scoringPlace,
                    companies,
                  },
                  index
                ) => (
                  <div
                    key={index}
                    className={`table__row table__item ${
                      index === openIndex ? "table__item_open" : ""
                    }`}
                  >
                    <div
                      className="table__item-wrapper"
                      onClick={() => this.openMoreInfo(index, iso)}
                    >
                      <div className="table__col2 table__country">
                        <div className="table__country_img">
                          {/* <CountryIcon /> */}
                          {/*<img style={{ width: '30px' }} src={countriesIcon[item.iso]} alt={item.countryRu} />*/}
                          <CountryFlag iso={iso} alt={countryRu} />
                        </div>
                        <div className="table__country_label">{countryRu}</div>
                      </div>
                      <div
                        className="table__col2 value table__import_bulk_volume_year"
                        data-tip={importVolume && importVolumeYear}
                        data-for={`table__tooltip-import_bulk_volume_year_${index}`}
                      >
                        <ReactTooltip
                          id={`table__tooltip-import_bulk_volume_year_${index}`}
                          type="light"
                          className="table__tooltip_box"
                        >
                          {importVolumeYear} год
                        </ReactTooltip>
                        {importVolumeFormated ? (
                          <span>{importVolumeFormated}</span>
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </div>
                      <div
                        className="table__col2 value"
                        data-tip={importFromRussia && importFromRussiaYear}
                        data-for={`table__tooltip-import_from_russia_year_${index}`}
                      >
                        <ReactTooltip
                          id={`table__tooltip-import_from_russia_year_${index}`}
                          type="light"
                          className="table__tooltip_box"
                        >
                          {importFromRussiaYear} год
                        </ReactTooltip>
                        {importFromRussiaPercent ? (
                          <span
                            className={`${importFromRussiaPercent.length === 1 ? "center" : ""}`}
                          >
                            {importFromRussiaPercent}
                          </span>
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </div>
                      <div className="table__col2 value table__tarif">
                        {avgAppliedTariffPercent ? (
                          <span
                            className={`${avgAppliedTariffPercent.length === 1 ? "center" : ""}`}
                          >
                            {avgAppliedTariffPercent}
                          </span>
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </div>
                      <div className="table__col1 value">
                        {nichesCount ? <Success /> : <span>&nbsp;</span>}
                      </div>
                      <div className="table__col1 value">
                        {barriersCount && (
                          <>
                            <span data-tip data-for={`table__barrier-warning-item${index}`}>
                              {barriersCount}
                            </span>
                            <ReactTooltip
                              id={`table__barrier-warning-item${index}`}
                              type="light"
                              className="table__tooltip_box_warning"
                              afterShow={() => this.getBarrier(iso)}
                              multiline
                            >
                              {barriersCounts ? (
                                <>
                                  {barriersCounts.barrierActive} действующих <br />
                                  {barriersCounts.barrierAdoptedNotInForce} принятых, не вступивших
                                  в силу
                                  <br />
                                  {barriersCounts.barrierInvestigation} в статусе расследования{" "}
                                  <br />
                                </>
                              ) : (
                                "Загружаю"
                              )}
                            </ReactTooltip>
                          </>
                        )}
                      </div>
                      <div className="table__col1 value table__companies">
                        <div className="table__companies-cell">
                          <span>{scoringPlace}</span>
                          {getIconHandsByCountryCompanies(companies)}
                        </div>
                      </div>
                      <div className="table__col1 table__col1--last">
                        <button type="button" className="btn table__btn_more-info">
                          <ChevronDown />
                        </button>
                      </div>
                    </div>
                    {country ? (
                      <div className="table__item_more-info">
                        {country.infoProduct && country.infoProduct.data ? (
                          <div className="more-info-table__section">
                            <div className="more-info-table__head">
                              {country.infoProduct.title}
                              <span className="target-product-country-info prim-col__accent">
                                {code} <i>{formattedQuery}</i>
                              </span>
                            </div>
                            <TradeProduct data={country.infoProduct.data} />
                          </div>
                        ) : null}
                        <div className="more-info-table__head">{country.about.title}</div>
                        <AboutTable data={country.about.data} />
                        <div className="btn-wrap__center more-info-table__more-btn">
                          <a
                            href={getUrls("COUNTRY", {
                              q: query,
                              code,
                              id: iso,
                            })}
                            className="btn btn-primary more-info-table__btn-more"
                          >
                            Перейти на следующий шаг
                            {!isLoad && (
                              <Tooltip
                                styleTooltip={{
                                  top: 26,
                                  right: -470,
                                  color: "black",
                                }}
                                message={HINTS.tableSelectCountryBtn.text}
                                isShow={isOn}
                                arrowSize="middle"
                                maxWidth="322"
                                styleArrow={{
                                  position: "absolute",
                                  top: "-16px",
                                  left: "-134px",
                                  transform: "scaleY(-1) rotate(33deg)",
                                }}
                              />
                            )}
                            <ChevronRight className={"btn__icon"} />
                          </a>
                          <button
                            type="button"
                            className="btn"
                            onClick={() => this.openMoreInfo(index, iso)}
                          >
                            <ChevronUp />
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )
              )}
          </div>
        </div>
        {isLoad ? <Loader /> : null}
      </div>
    );
  }
}

export default connect("hint", memo(RatingExport));
