import React, { Component } from "react";
import ModalBody from "./Modal";
import "./modal.css";

class Modal extends Component {
  render() {
    const { isOpen } = this.props;
    return isOpen ? <ModalBody {...this.props} /> : null;
  }
}

export default Modal;
