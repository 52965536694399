export const elasticSearch = (input, dataArray, arrayOfSearching) => {
  if (input) {
    const searchPattern = new RegExp(input, "i");
    return dataArray.filter((item) => {
      const result = arrayOfSearching.some((searchCondition) =>
        searchPattern.test(item[searchCondition])
      );
      return result && item;
    });
  }
  return dataArray;
};
