import React from "react";
import connect from "storeon/react/connect";

import Icons from "../Icons";
import "./SwitchHint.sass";

const SwitchHint = (props) => {
  const {
    hint: { isOn },
    dispatch,
  } = props;

  const switchModeHint = () => dispatch("hint/switch", !isOn);

  return (
    <div className="switchHint__wrapper" onClick={switchModeHint}>
      <div className="switchHint__onWrapper switchHint__content">
        <div className="switchHint__circleHint">{isOn ? <Icons.Close /> : <Icons.Add />}</div>
        <span className="switchHint__text">
          {isOn ? "Выключить подсказки" : "Включить подсказки"}
        </span>
      </div>
    </div>
  );
};

export default connect("hint", SwitchHint);
