import createStore from "storeon";

let user = (store) => {
  // Initial state
  store.on("@init", () => ({
    user: { displayName: null, displayNameCurrentOrgId: null },
    hint: { isOn: true },
  }));
  // eslint-disable-next-line
  store.on("user/add", ({}, user) => {
    return {
      user: { ...user },
    };
  });
};

const hint = (store) => {
  // eslint-disable-next-line
  store.on("hint/switch", ({}, isOn) => ({ hint: { isOn } }));
};

export const store = createStore([
  user,
  hint,
  process.env.NODE_ENV !== "production" && require("storeon/devtools"),
]);
