import React, { memo, useMemo, useState } from "react";
import "./index.sass";
import { ReactComponent as ChevronDown } from "../../images/icons/chevron-down.svg";

const BarrierInformation = ({ data }) => {
  return (
    <div className="barrier-table__box-text">
      <div className="barrier-table__sub-title">{data.title}</div>
      <div
        className="barrier-table__descript"
        dangerouslySetInnerHTML={{ __html: data.description }}
      />
      <div className="barrier-table__date-upd">
        <span className="barrier-table__accent-text">Дата последнего изменения: </span>
        {data.updated_date}
      </div>
      {/*<div className="barrier-table__source-info">*/}
      {/*  <span className="barrier-table__accent-text">Источник: </span>{item.source}*/}
      {/*</div>*/}
    </div>
  );
};

const BarriersInformation = ({ summary = [], data = [], pageSize = 3 }) => {
  const [pageNum, setPageNum] = useState(1);

  const pages = useMemo(() => {
    const fullBarrierList = data.map((item, index) => (
      <BarrierInformation data={item} key={index} />
    ));
    const _pages = [];
    while (fullBarrierList.length) {
      _pages.push(fullBarrierList.splice(0, pageSize));
    }
    return _pages;
  }, [data, pageSize]);

  return (
    <div className="barrier">
      <div className="barrier__inner-label">Нетарифные барьеры</div>
      <div className="barrier-table__box">
        {summary.map((item, index) => (
          <div className="barrier-table__item" key={index}>
            <div>{item.label}</div>
            <div className="barrier-table__item-accent">{item.value}</div>
          </div>
        ))}
      </div>
      {pages.length > 0 ? (
        <>
          <div className="barrier__inner-label">Список применяемых нетарифных барьеров</div>
          <div className="barrier-table__box">{pages.slice(0, pageNum).flat(1)}</div>
        </>
      ) : null}
      {pageNum < pages.length ? (
        <div className="btn-wrap__center">
          <button
            type="button"
            className="btn-empty"
            onClick={() => setPageNum((prev) => prev + 1)}
          >
            Показать ещё
            <ChevronDown className="rating-export__btn_icon" />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default memo(BarriersInformation);
