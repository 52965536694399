import PropTypes from "prop-types";

export const dataPropType = PropTypes.oneOfType([
  PropTypes.exact({
    groups: PropTypes.array,
    nomenclatures: PropTypes.array,
    goods: PropTypes.array,
  }),
  PropTypes.arrayOf(PropTypes.object),
  PropTypes.arrayOf(PropTypes.string),
]);

export const layoutTypePropType = PropTypes.oneOf(["default", "alternate"]);
