/* eslint-disable */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import AutoCompleteVariants from "./AutoCompleteVariants";
import styles from "./AutoComplete.module.scss";
import { dataPropType, layoutTypePropType } from "./helpers/propTypesHelpers";
import { Icons } from "elk-uikit";
import { elasticSearch } from "./helpers/helpers";

const ARROW_UP = "ArrowUp";
const ARROW_DOWN = "ArrowDown";
const ENTER = "Enter";
const ESCAPE = "Escape";
const EXCLUDED_KEYS = [
  ARROW_UP,
  ARROW_DOWN,
  "ArrowRight",
  "ArrowLeft",
  ENTER,
  "Meta",
  "Control",
  "CapsLock",
  "Shift",
  "Escape",
  "Alt",
  "Tab",
  "Home",
  "End",
  "PageDown",
  "PageUp",
  "NumLock",
];
const EXCLUDED_FN_KEYS = Array(19)
  .fill(0)
  .map((e, i) => `F${i + 1}`);
function checkIfBrowserIE() {
  if (/* @cc_on!@ */ false || !!document.documentMode) return true;
  return false;
}
const SIZE_ICON = 32;

const AutoComplete = (props) => {
  const {
    dataSource = [],
    arrayOfProps,
    selectedValue,
    onSelect,
    inputValue,
    inputOnChange, // must be wrapped in useCallback
    label,
    style,
    layoutType,
    isMoreButton,
    // showAllHandler,
    onFocus,
    onBlur,
    clearInput,
    isSimpleSearch,
    isHideSearch,
    handleOnStopWriting,
    elementInInput,
    elementInItem,
    rightElementInItem,
    isBackSideSearch,
    showCloseLeft,
    classNameInput,
    exceptionsIdShowingList,
    hideList,
    isPainted,
    autoFocus,
  } = props;
  const inputRef = useRef(null);
  const [inputState, setInputState] = useState("");
  const [isFocused, setFocus] = useState(false);
  const [isShowMore, setShowMore] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  let timeoutKeyUp = null;

  const onInputChange = ({ target }) => {
    if (typeof inputOnChange !== "function") return setInputState(target.value);
    return inputOnChange(target.value);
  };

  const onInputFocus = () => {
    onFocus();
    setFocus(true);
  };

  const onInputClear = useCallback(() => {
    if (clearInput) clearInput();
    setInputState("");
  }, [clearInput]);

  const onInputBlur = (event) => {
    const relatedTarget = checkIfBrowserIE() ? document.activeElement : event.relatedTarget;
    const { current } = inputRef;
    const isArrowButtonClicked =
      relatedTarget && relatedTarget.type === "button" && current.nextSibling === relatedTarget;
    const isVariantClicked =
      relatedTarget &&
      relatedTarget.tagName === "LI" &&
      relatedTarget.parentElement === current.parentElement.nextElementSibling;
    const showMoreClick = relatedTarget && relatedTarget.tagName === "DIV";
    if (isArrowButtonClicked || isVariantClicked) {
      setFocus(true);
      return current.focus();
    }
    if (showMoreClick) {
      setFocus(true);
      setShowMore(true);
      return current.focus();
    }
    onBlur();
    return setFocus(false);
  };

  const incrementVariant = () => {
    const listElement = document.querySelector(`.${styles.wrapper} > ul`);
    const {
      scrollTop,
      firstChild: { scrollHeight: scrollHeightItem },
    } = listElement;
    if (selectedIndex === dataSource.length - 1) {
      listElement.scrollTop = 0;
      return setSelectedIndex(0);
    }
    listElement.scrollTop =
      scrollTop >= scrollHeightItem * setSelectedIndex ? 0 : scrollTop + scrollHeightItem;
    return setSelectedIndex(selectedIndex + 1);
  };

  const decrementVariant = () => {
    const listElement = document.querySelector(`.${styles.wrapper} > ul`);
    const { scrollHeight } = listElement.firstChild;
    if (selectedIndex === 0) {
      listElement.scrollTop = listElement.scrollHeight;
      return setSelectedIndex(dataSource.length - 1);
    }
    listElement.scrollTop -= scrollHeight;
    return setSelectedIndex(selectedIndex - 1);
  };

  const handleOnDownUp = () => {
    clearTimeout(timeoutKeyUp);
  };

  const handleOnKeyUp = (key) => {
    if (![...EXCLUDED_FN_KEYS, ...EXCLUDED_KEYS].includes(key) && handleOnStopWriting) {
      clearTimeout(timeoutKeyUp);
      timeoutKeyUp = setTimeout(() => {
        handleOnStopWriting();
      }, 1000);
    }
  };

  const onKeyupHandler = ({ key }) => {
    handleOnKeyUp(key);
    switch (key) {
      case ARROW_UP:
        return decrementVariant();
      case ARROW_DOWN:
        return incrementVariant();
      case ESCAPE:
        return clearInput();
      case ENTER: {
        onSelect(dataSource[selectedIndex]);
        return inputRef.current.blur();
      }
      default:
        return false;
    }
  };

  const onKeyupAlternateHandler = ({ key, target }) => {
    handleOnKeyUp(key);
    if (key === ENTER) {
      clearInput();
      return target.blur();
    }
    return false;
  };

  useEffect(() => {
    if (isSimpleSearch) return;
    // if (selectedValue !== null && layoutType === 'default') {
    // onInputChange(selectedValue[arrayOfProps[0]]);
    // if (typeof inputOnChange === 'function') {
    //   // inputOnChange(selectedValue[arrayOfProps[0]]);
    // } else {
    //   setInputState(selectedValue[arrayOfProps[0]]);
    // }
    // }
  }, [arrayOfProps, inputOnChange, layoutType, selectedValue, isSimpleSearch]);

  const dataForAutocomplete =
    !isShowMore && isMoreButton && layoutType === "default" ? dataSource.slice(0, 4) : dataSource;

  const showMoreHandle = () => {
    setShowMore(true);
  };

  const computedInputValue = useMemo(() => {
    if (typeof inputValue !== "string") return inputState;
    return inputValue;
  }, [inputValue, inputState]);

  const finalData = isBackSideSearch
    ? dataForAutocomplete
    : elasticSearch(inputValue, dataForAutocomplete, arrayOfProps);

  const searchButtonStyles = useMemo(
    () =>
      cn(styles.button, styles.search, isFocused || computedInputValue ? styles.hide : styles.show),
    [isFocused, computedInputValue]
  );

  const closeButtonStyles = useMemo(
    () =>
      cn(
        styles.button,
        styles.close,
        showCloseLeft && styles.left,
        (!showCloseLeft || isFocused || isSimpleSearch) && computedInputValue
          ? styles.show
          : styles.hide
      ),
    [isFocused, computedInputValue, isSimpleSearch, showCloseLeft]
  );

  const inputStyles = useMemo(
    () =>
      cn(
        styles.input,
        {
          [styles.focusedOrFullfilled]:
            (!showCloseLeft && (isFocused || computedInputValue)) ||
            (showCloseLeft &&
              ((!isFocused && computedInputValue) || (isFocused && !computedInputValue))),
          [styles.withoutIconSearch]: isHideSearch,
          [styles.hideText]: !isFocused,
        },
        classNameInput
      ),
    [isFocused, computedInputValue, isHideSearch, showCloseLeft]
  );

  const inputWrapperStyles = useMemo(
    () =>
      cn(styles.inputWrapper, {
        [styles.active]: isFocused,
        [styles.inactive]: !isFocused,
        [styles.painted]: isPainted && !isFocused,
      }),
    [isFocused]
  );

  const onSearchClick = useCallback(() => {
    setFocus(true);
    const { current } = inputRef;
    return current.focus();
  }, []);

  return (
    <div className={styles.wrapper} style={style} onBlur={() => setShowMore(false)}>
      <div className={inputWrapperStyles}>
        {elementInInput}
        {(!isHideSearch || (showCloseLeft && !isFocused)) && (
          <button type="button" className={searchButtonStyles} onClick={onSearchClick}>
            <Icons.Search width={SIZE_ICON} height={SIZE_ICON} fill="#9C9D9E" />
          </button>
        )}
        <input
          autoFocus={autoFocus}
          value={computedInputValue}
          onChange={onInputChange}
          className={inputStyles}
          ref={inputRef}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          placeholder={label}
          onKeyUp={layoutType === "default" ? onKeyupHandler : onKeyupAlternateHandler}
          onKeyDown={handleOnDownUp}
        />
        <button type="button" onClick={onInputClear} className={closeButtonStyles}>
          <Icons.Close width={11} height={11} />
        </button>
      </div>
      {!isSimpleSearch && isFocused && (
        <AutoCompleteVariants
          fullData={dataSource}
          dataSource={finalData}
          arrayOfProps={arrayOfProps}
          onSelect={onSelect}
          textToHighLight={computedInputValue}
          index={selectedIndex}
          setIndex={setSelectedIndex}
          isMoreButton={isMoreButton}
          // showAllHandler={showAllHandler}
          clearInput={onInputClear}
          showMore={showMoreHandle}
          isShowMore={isShowMore}
          layoutType={layoutType}
          inputRef={inputRef}
          elementInItem={elementInItem}
          rightElementInItem={rightElementInItem}
          exceptionsIdShowingList={exceptionsIdShowingList}
          hideList={hideList}
        />
      )}
    </div>
  );
};

AutoComplete.propTypes = {
  dataSource: dataPropType,
  arrayOfProps: dataPropType,
  selectedValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSelect: PropTypes.func,
  autoFocus: PropTypes.bool,
  inputValue: PropTypes.string,
  inputOnChange: PropTypes.func,
  label: PropTypes.string,
  style: PropTypes.shape(),
  classNameInput: PropTypes.string,
  layoutType: layoutTypePropType,
  isMoreButton: PropTypes.bool,
  isHideSearch: PropTypes.bool,
  isSimpleSearch: PropTypes.bool,
  handleOnStopWriting: PropTypes.func,
  clearInput: PropTypes.func,
  showCloseLeft: PropTypes.bool,
  elementInInput: PropTypes.node,
  elementInItem: PropTypes.func,
  rightElementInItem: PropTypes.func,
  isBackSideSearch: PropTypes.bool,
  exceptionsIdShowingList: PropTypes.arrayOf(PropTypes.any.isRequired),
  hideList: PropTypes.bool,
  isPainted: PropTypes.bool,
};

AutoComplete.defaultProps = {
  dataSource: [],
  arrayOfProps: [""],
  selectedValue: "",
  onSelect: () => false,
  onFocus: () => undefined,
  onBlur: () => undefined,
  autoFocus: false,
  inputValue: null,
  inputOnChange: null,
  clearInput: () => {},
  label: "Поиск...",
  style: {},
  isMoreButton: false,
  isHideSearch: false,
  layoutType: "default",
  classNameInput: "",
  isSimpleSearch: false,
  handleOnStopWriting: null,
  showCloseLeft: false,
  elementInInput: null,
  elementInItem: null,
  rightElementInItem: null,
  isBackSideSearch: false,
  exceptionsIdShowingList: null,
  hideList: false,
  isPainted: false,
};

export default memo(AutoComplete);
