import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { store } from "./store";
import {
  Country,
  CustomersSearch,
  Error401,
  Error404,
  Error500,
  Home,
  RatingDetails,
  Result,
} from "./pages";
import { PATHS, decodeMatchParams } from "./const";
import { useUidmSSO } from "uidm-react-lib";
import api from "./api";

function App({ config: menuConfig = {} }) {
  const { sso } = useUidmSSO();
  const { logged, userData } = sso;

  useEffect(() => {
    store.dispatch("user/add", logged ? userData || {} : {});
  }, [logged, userData]);

  api.sso = sso;
  return (
    <Router>
      <Switch>
        <Route
          path={PATHS.HOME}
          exact
          render={(props) => (
            <Home {...props} menuConfig={menuConfig} match={decodeMatchParams(props)} />
          )}
        />
        <Route
          path={PATHS.RESULT}
          exact
          render={(props) => (
            <Result {...props} menuConfig={menuConfig} match={decodeMatchParams(props)} />
          )}
        />
        <Route
          path={PATHS.COUNTRY}
          exact
          render={(props) => (
            <Country {...props} menuConfig={menuConfig} match={decodeMatchParams(props)} />
          )}
        />
        <Route
          path={PATHS.RATING_DETAILS}
          render={(props) => (
            <RatingDetails {...props} menuConfig={menuConfig} match={decodeMatchParams(props)} />
          )}
        />
        <Route
          path={PATHS.CUSTOMERS_SEARCH}
          render={(props) => (
            <CustomersSearch {...props} menuConfig={menuConfig} match={decodeMatchParams(props)} />
          )}
        />
        <Route
          path={PATHS.ERROR401}
          render={(props) => (
            <Error401 {...props} menuConfig={menuConfig} match={decodeMatchParams(props)} />
          )}
        />
        <Route
          path={PATHS.ERROR500}
          render={(props) => (
            <Error500 {...props} menuConfig={menuConfig} match={decodeMatchParams(props)} />
          )}
        />
        <Route
          render={(props) => (
            <Error404 {...props} menuConfig={menuConfig} match={decodeMatchParams(props)} />
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
