import React, { memo } from "react";

import Modal from "../../layout/Modal";
import "./terms-modal.sass";

const Terms = ({ portalUrl, className, ...rest }) => {
  return (
    <Modal className={className || "terms-modal"} {...rest}>
      <p className="text-center">Уважаемые пользователи, обращаем Ваше внимание:</p>
      <ul>
        <li>
          все сведения, содержащиеся в данном Сервисе, носят исключительно информационный характер;
        </li>
        <li>
          Оператор ИС «Одно окно» не утверждает, не гарантирует и не заверяет, что вся информация,
          содержащаяся в данном Сервисе, является единственно достоверной и верной;
        </li>
        <li>
          Оператор ИС «Одно окно» не несет ответственности за использование информации, сведений,
          указанных в данном Сервисе, а также за возможные убытки, расходы, потери или иного рода
          финансовые издержки от любых сделок, совершенных пользователем (получателем) данного
          Сервиса на основании его содержания;
        </li>
        <li>
          во всех случаях использования информации и разъяснений, содержащихся в данном Сервисе,
          определение соответствия предоставленных в данном Сервисе информации и разъяснений
          желаемым целям пользователя (получателя) данного Сервиса, а также потенциальному риску
          такого использования является задачей пользователя (получателя) данного Сервиса;
        </li>
        <li>
          исключительные права на данный Сервис или его части принадлежат Оператору ИС «Одно окно».
          Заявитель с использованием Сервиса получает соответствующие материалы с правом
          воспроизводить и просматривать материалы для собственных целей без выплаты вознаграждения.
        </li>
        <li>
          запрещена любая передача информации данного Сервиса третьим лицам, а также его
          распространение.
        </li>
      </ul>
      <p className="text-center">В случае возникновения вопросов:</p>
      <p>
        Вы всегда можете обратиться в службу поддержки системы{" "}
        <a href={`${portalUrl}/support/`} target="_blank" rel="noopener noreferrer">
          «Мой Экспорт»
        </a>
        . Также будем благодарны за Ваши комментарии и пожелания, которые Вы можете оставить в Вашем
        личном кабинете.
      </p>
    </Modal>
  );
};

export default memo(Terms);
