import React, { Component } from "react";
import { Button } from "elk-uikit";

import api from "../../api";
import { debounce, makeCancelable } from "../../utils";
import { ReactComponent as InfoIcon } from "../../images/icons/info.svg";

import ReactTooltip from "../ReactTooltip";
import AutoComplete from "../AutoComplete";

import "./search-navigator.sass";

const ARRAY_PROPS_SEARCH = ["value"];
const EXCEPTIONS_ID_SHOWING_LIST = ["more-info", "more-info-wrapper", "more-info-tooltip"];
const PRACTICE = "Практика";
const HS = "HS";
const TN_VED = "ТН ВЭД";
const PRACTICE_TEXT =
  "В процессе поиска кода ТН ВЭД использованы примеры описания товаров при их декларировании";
const TN_VED_TEXT = "Поиск кода ТН ВЭД произведен по названиям кодов ТН ВЭД на уровне 6-10 знаков";
const HS_TEXT = "Поиск кода ТН ВЭД произведен по названиям кодов справочника HS на уровне 6 знаков";

const TOOLTIP_TEXT = {
  [PRACTICE]: PRACTICE_TEXT,
  [HS]: HS_TEXT,
  [TN_VED]: TN_VED_TEXT,
};

class SearchInput extends Component {
  state = {
    helpValue: "",
    options: [],
    cursor: 0,
    error: false,
  };

  componentDidUpdate(prevProps) {
    const { code: currentCode } = this.props;
    const { code: oldCode } = prevProps;
    if (currentCode !== oldCode) {
      this.setState({
        helpValue: "",
      });
    }
  }

  promise = null;
  search = debounce((inputValue) => {
    if (this.promise) {
      this.promise.cancel();
    }

    const value = `${inputValue}`.trim();
    if (!value.length) {
      return;
    } else if (!/\d+/.test(value) && value.length < 2) {
      return;
    }

    this.promise = makeCancelable(
      api
        .search({ q: value })
        .then((res) => {
          this.props.loadTooltips(res, value);
          return res;
        })
        .then((res) => {
          const { matchBeforeClick } = this.props;
          const firstLabel = res.length > 0 ? res[0].label.toLocaleLowerCase() : "";
          let helpValue = "";
          if (
            value !== "" &&
            firstLabel.slice(0, value.length).includes(value.toLocaleLowerCase())
          ) {
            helpValue = firstLabel;
          }
          this.setState(
            {
              helpValue,
              options: res.map((item) => ({
                value: `${item.article} ${item.label}`,
                ...item,
              })),
              cursor: 0,
              error: false,
            },
            () =>
              matchBeforeClick({
                option: this.state.options,
                value: value.substr(0, 6),
              })
          );
        })
        .catch((error) => {
          this.setState({
            error: true,
            options: [],
          });
        })
    );
  }, 300);

  handleChange = (value) => {
    const { updateQueryText } = this.props;
    const trimmedValue = value && value.trimStart();

    const efficientFn = this.search;
    updateQueryText({
      query: trimmedValue,
      callback: () => {
        efficientFn(trimmedValue);
      },
    });
  };

  handleSelectOption = (item) => {
    if (!item) return;
    const { value, article: code } = item;
    const { updateQueryText, value: inputValue, sendFeedback } = this.props;
    const { options } = this.state;
    updateQueryText({
      query: value,
      isOption: true,
      code,
    });

    this.setState({
      helpValue: value,
      options: options.filter((item) => item.value === value),
      cursor: 0,
    });
    sendFeedback(inputValue, code, "search");
  };

  handleOnClear = () => {
    const { onClear } = this.props;
    this.setState({ options: [] });
    onClear();
  };

  renderElementInItem = ({ tags = [] }) => (
    <div className="autocomplete__tags">
      {tags.map((item) => (
        <>
          <span key={item} className="autocomplete__tags-item">
            {item}
          </span>
          <ReactTooltip style={{ marginLeft: "10px" }} text={TOOLTIP_TEXT[item] || ""} />
        </>
      ))}
    </div>
  );

  render = () => {
    const { options } = this.state;
    const {
      value,
      code,
      closeTooltip,
      openTooltip,
      isDisabled,
      handleClickButton,
      isPainted,
      showCloseLeft = true,
      autoFocus = false,
    } = this.props;

    return (
      <div id="autocomplete">
        <AutoComplete
          style={{
            width: "100%",
            "& div ul": {
              height: "400px!important",
            },
          }}
          autoFocus={autoFocus}
          label="Введите код ТН ВЭД или название товара"
          inputValue={value}
          inputOnChange={this.handleChange}
          dataSource={options}
          onSelect={this.handleSelectOption}
          arrayOfProps={ARRAY_PROPS_SEARCH}
          clearInput={this.handleOnClear}
          classNameInput="searchInput"
          exceptionsIdShowingList={EXCEPTIONS_ID_SHOWING_LIST}
          elementInInput={
            <Button
              disabled={isDisabled || code !== value.split(" ")[0] || code === ""}
              className="selectCountriesBtn"
              type="submit"
              variant="secondary"
              width="200px"
              onClick={() => handleClickButton()}
            >
              Подобрать страны
            </Button>
          }
          rightElementInItem={(item) => (
            <div
              style={{
                height: "100%",
              }}
              data-tip
              data-for="more-info"
              onMouseOver={() => openTooltip(this.targetTooltip, item.article, item.label)}
              onMouseOut={() => closeTooltip(this.targetTooltip)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openTooltip(this.targetTooltip, item.article, item.label);
              }}
            >
              {item.article ? (
                <div
                  style={{
                    padding: "5px",
                  }}
                  ref={(ref) => (this.targetTooltip = ref)}
                  className="autocomplete__more-btn"
                >
                  <InfoIcon />
                  <span>Подробнее</span>
                </div>
              ) : null}
            </div>
          )}
          elementInItem={this.renderElementInItem}
          isPainted={isPainted}
          showCloseLeft={showCloseLeft}
          isBackSideSearch
        />
      </div>
    );
  };
}

export default SearchInput;
