import React from "react";
import Layout from "../layout";

const NotFound = ({ menuConfig }) => {
  return (
    <Layout title="404" menuConfig={menuConfig}>
      <section className="notfound__section">
        <div className="container" style={{ display: "flow-root" }}>
          <div className="row notfound__box">
            <div className="notfound__left">
              <div className="notfound__lg-title">404</div>
            </div>

            <div className="notfound__right">
              <h1 className="notfound__label">Ошибка 404</h1>
              <div className="notfound__descr">Страница не найдена</div>
              <div className="notfound__small-text">
                Неправильно набран адрес или такой
                <br />
                страницы не существует
              </div>
              <a href="/" className="btn btn-primary">
                Перейти на главную
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFound;
