import React from "react";
import { Link } from "react-router-dom";

import "./index.sass";

const BackBtn = ({ path }) => (
  <Link to={path} className="backBtn">
    Назад
  </Link>
);

export default BackBtn;
