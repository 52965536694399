import React, { memo } from "react";
import PropTypes from "prop-types";

const Add = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0.813004C9.39765 0.813004 9.72 1.13536 9.72 1.533L9.72 8.28005L16.467 8.28005C16.8647 8.28005 17.187 8.60241 17.187 9.00005C17.187 9.3977 16.8647 9.72005 16.467 9.72005L9.72 9.72005L9.72 16.4671C9.72 16.8647 9.39765 17.1871 9 17.1871C8.60235 17.1871 8.28 16.8647 8.28 16.4671L8.28 9.72005L1.53295 9.72005C1.13531 9.72005 0.812953 9.3977 0.812953 9.00005C0.812953 8.60241 1.13531 8.28005 1.53295 8.28005L8.28 8.28005L8.28 1.533C8.28 1.13536 8.60235 0.813004 9 0.813004Z"
      fill={fill}
    />
  </svg>
);

Add.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

Add.defaultProps = {
  width: 18,
  height: 18,
  fill: "#fff",
};

export default memo(Add);
