export const DEBOUNCE_TIME = 500;

export const MIN_COUNT_COUNTRY_FOR_HINT = 4;

export const RADIO = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
  {
    value: 250,
    label: "Все",
  },
];
